import { database, functions } from 'utility/appwriteClient';

function misOrderLodge(tentant: any, packageId: any) {

    
    const closureRequest = functions.createExecution('misCompleteOrder',
            '{"tentant": "' + tentant + '","package_id": "' + packageId + '"}'
        )

return ( null )

}

export { misOrderLodge }