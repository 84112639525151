import { useContext, useEffect, useState } from "react";
import { TentantContext } from "contexts/tentant";

import {
    Checklist,
    LocalShipping,
    Extension,
    Dashboard,
    Mail,
} from "@mui/icons-material";

export const Resources = ({tentant = ''}) => {

    const [tentantName, setTentantName] = useState(tentant);

    useEffect(() => {

        if (tentant != '') {
            setTentantName('/' + tentant)
        }

    }, [tentant]);  

    if (tentant === 'Admin') {
        return [
            {
                name: "dashboard",
                list: tentantName + "/dashboard",
                meta: {
                    label: "Dashboard",
                }
            },
            {
                name: "Create New",
                list: tentantName + "/tentant",
                meta: {
                    label: "Create New Tentant",
                    canDelete: true,
                },
            },
            {
                name: "Team Members",
                list: tentantName + "/teams",
                create: tentantName + "/categories/create",
                edit: tentantName + "/categories/edit/:id",
                show: tentantName + "/teams/show/:id",
                meta: {
                    canDelete: true,
                },
            },
        ]
    } else {
    
    return (
    [
        {
            name: "dashboard",
            list: tentantName + "/dashboard",
            meta: {
                label: "Dashboard",
                icon: <Dashboard/>,
            }
        },
        {
            name: "lodgement",
            list: tentantName + "/lodgement",
            meta: {
                label: "Lodge New Package",
                icon: <Checklist/>,
            },
        },
        // {
        //     name: "Lodged Packages",
        //     list: tentantName + "/categories",
        //     create: tentantName + "/categories/create",
        //     edit: tentantName + "/categories/edit/:id",
        //     show: tentantName + "/categories/show/:id",
        //     meta: {
        //         label: "Lodged Packages",
        //         canDelete: true,
        //         icon: <LocalShipping/>,                
        //     },
        // },
        {
            name: "Lodged Packages",
            list: tentantName + "/lodgements",
            meta: {
                label: "Lodged Packages",
                icon: <LocalShipping/>,
            },
        },
        {
            name: "plugins",
            list: tentantName + "/plugins",
            meta: {
                label: "Plugins",
                icon: <Extension/>,
            },
        },
        {
            name: "Packages",
            list: tentantName + "/packages",
            meta: {
                label: "Packages",
                icon: <Mail/>,                
            },
        },
    ]
    )}
};

export const accessProvider = [

]

