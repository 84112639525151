import { useEffect, useState, useContext } from 'react';
import { database } from 'utility/appwriteClient';
import { TentantContext } from "contexts/tentant";
import { PluginItem } from './item';
import { PluginList } from 'interfaces';



const PluginsPage = () => {
  const [plugins, setPlugins] = useState<PluginList[]>([]);

  //Get Context
  const [tentant] = useContext(TentantContext);

  useEffect(() => {

    database.listDocuments(tentant.toLowerCase(), tentant.toLowerCase() + '_plugin')
      .then((response) => {
        setPlugins(response.documents as unknown as PluginList[]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      {plugins.map((plugin) => (
        <PluginItem key={plugin.$id} plugin={plugin} />
      ))}
    </div>
  );
};

export default PluginsPage;
