import { database, functions } from 'utility/appwriteClient';

function SynergyComplete(tentant: any, packageId: any) {

    
    const closureRequest = functions.createExecution('synergyCompleteOrder',
            '{"tentant": "' + tentant + '","package_id": "' + packageId + '"}'
        )

return ( null )

}

export { SynergyComplete }