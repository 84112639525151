import React, { useContext } from "react";
import { useGetIdentity } from "@refinedev/core";
import {
    AppBar,
    IconButton,
    Avatar,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import { RefineThemedLayoutV2HeaderProps, HamburgerMenu } from "@refinedev/mui";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";

import { ColorModeContext } from "../../contexts/color-mode";
import { TentantContext } from "../../contexts/tentant";

import ZPL_Printer from "./printer";
import ScaleConnect from "./scale";
import QZKey from "./printerKey"

type IUser = {
    id: number;
    name: string;
    avatar: string;
};

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = () => {
    const { mode, setMode } = useContext(ColorModeContext);
    const [tentant] = useContext(TentantContext);

    const { data: user } = useGetIdentity<IUser>();

    return (
        <AppBar position="sticky">
            <Toolbar>
                <Stack
                    direction="row"
                    width="100%"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <HamburgerMenu />
                    <Stack
                        direction="row"
                        width="100%"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        {tentant}
                    </Stack>
                    <Stack
                        direction="row"
                        width="100%"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <QZKey/>
                        <ScaleConnect/>
                        <ZPL_Printer/>
                        <IconButton
                            color="inherit"
                            onClick={() => {
                                setMode();
                            }}
                        >
                            {mode === "dark" ? (
                                <LightModeOutlined />
                            ) : (
                                <DarkModeOutlined />
                            )}
                        </IconButton>

                        {(user?.avatar || user?.name) && (
                            <Stack
                                direction="row"
                                gap="16px"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {user?.name && (
                                    <Typography
                                        sx={{
                                            display: { xs: "none", sm: "inline-block" },
                                        }}
                                        variant="subtitle2"
                                    >
                                        {user?.name}
                                    </Typography>
                                )}
                                {/* <Avatar src={user?.avatar} alt={user?.name} /> */}
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};
