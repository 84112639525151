import React, {
    PropsWithChildren,
    createContext,
    useEffect,
    useState,
} from "react";
import { ThemeProvider, createTheme  } from "@mui/material/styles";
import { RefineThemes } from "@refinedev/mui";

type ColorModeContextType = {
    mode: string;
    setMode: () => void;
};

export const ColorModeContext = createContext<ColorModeContextType>(
    {} as ColorModeContextType,
);

export const ColorModeContextProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const colorModeFromLocalStorage = localStorage.getItem("colorMode");
    const isSystemPreferenceDark = window?.matchMedia(
        "(prefers-color-scheme: dark)",
    ).matches;

    const systemPreference = isSystemPreferenceDark ? "dark" : "light";
    const [mode, setMode] = useState(
        colorModeFromLocalStorage || systemPreference,
    );

    useEffect(() => {
        window.localStorage.setItem("colorMode", mode);
    }, [mode]);

    const setColorMode = () => {
        if (mode === "light") {
            setMode("dark");
        } else {
            setMode("light");
        }
    };

    const themeLight = createTheme({
        ...RefineThemes.Orange,
        palette: {
          ...RefineThemes.Orange.palette,
          background: {
            default: "#f0f0f0"
          }
        }
      });
      
      const themeDark = createTheme({
        ...RefineThemes.OrangeDark,
        palette: {
          ...RefineThemes.OrangeDark.palette,
          background: {
            default: "#222222"
          }
        }
      });

    return (
        <ColorModeContext.Provider
            value={{
                setMode: setColorMode,
                mode,
            }}
        >
            <ThemeProvider
                theme={
                    mode === "light" ? themeLight : themeDark
                }
            >
                {children}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};
