import { Item } from 'interfaces';

export function sortData(synergyData: any, shipFrom: any) {

    let packages = [];
    let newItem : Item;

    newItem = {
        id: String(Date.now()),
        type: '',
        Package_Name: 'Custom',
        Package_Type: 'carton',
        Package_Length: 0,
        Package_Width: 0,
        Package_Height: 0,
        Package_Weight: 0,
        Package_Quantity: 0,
        Item_Quantity: 0,
    };

    let items = [newItem]
    let stateSet = ''
    let citySet = ''
    let intState = ''

    if (synergyData.deliveryData[0].Address_State != "VIC" || synergyData.deliveryData[0].Address_State != "QLD" || synergyData.deliveryData[0].Address_State != "NSW"
    || synergyData.deliveryData[0].Address_State != "ACT" || synergyData.deliveryData[0].Address_State != "NT" || synergyData.deliveryData[0].Address_State != "SA"
    || synergyData.deliveryData[0].Address_State != "WA" || synergyData.deliveryData[0].Address_State != "TAS") {
        stateSet = 'INT'
        intState = synergyData.deliveryData[0].Address_State
        citySet = synergyData.deliveryData[0].Address_City
    } else {
        stateSet = synergyData.deliveryData[0].Address_State
        citySet = synergyData.deliveryData[0].Address_City
    }

    const newPackage = {
        shipFrom: shipFrom,
        orderId: synergyData.data.Synergy_Unique_ID,
        shipTo: synergyData.deliveryData[0].Customer_Name,
        phone: synergyData.deliveryData[0].Customer_Phone,
        email: synergyData.deliveryData[0].Customer_Email,
        address1: synergyData.deliveryData[0].Address_Line_One,
        address2: synergyData.deliveryData[0].Address_Line_Two,
        city: citySet,
        postcode: synergyData.deliveryData[0].Address_Postcode,
        state: stateSet,
        intState: intState,
        country: synergyData.deliveryData[0].Address_Country,
        authority: 'false',
        items: items,
        instructions: synergyData.deliveryData[0].Shipment_Instructions,
        description: synergyData.deliveryData[0].Shipment_Description,
        error: 'items not found'
    };

    packages.push(newPackage)

    return { packages };

}