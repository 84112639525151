const preferredLogic = (packageData: any, orderData: any) => {

    //VIC
    if (packageData.state == 'VIC') {
        return ('Local')
    }

    //NSW
    if (packageData.state == 'NSW') {
        if (parseInt(packageData.postcode) > 2234  && orderData.Description.includes('Cards')) {
            return ('STEFPP') //AIR
        }
        else {
            return ('STEROAD') //ROAD
        }
    }

    //SA
    if (packageData.state == 'SA') {
        if (parseInt(packageData.postcode) > 5199 && orderData.Description.includes('Cards')) {
            return ('STEFPP') //AIR
        }
        else {
            return ('STEROAD') //ROAD
        }
    }

    if (packageData.state != 'SA' && packageData.state !='NSW' && packageData.state != 'VIC') {
        if (orderData.Description.includes('Cards')) {
            return ('STEFPP') //AIR
        }
        else {
            return ('STEROAD') //ROAD
        }

    }

    return (
        null
    )

}

const addOnLogic = (packageData: any, orderData: any, shippingData: any) => {
    
    var returnData = []

    if (orderData.CentreID === "10312" && orderData.DeliveryType.Name === ("ToCustomer") && orderData.OrderNonCentreDeliveries[0].Address.CompanyName.includes('Officeworks IQ')) {
        returnData.push({ "Name": "Officeworks IQ" })
    }

    if (shippingData === 'STEFPP') {
        returnData.push({ "Name": "Air" })
    }

    return (
        returnData
    )

}

export { preferredLogic, addOnLogic };