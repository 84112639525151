import { Authenticated} from '@refinedev/core';
import { ThemedLayoutV2 } from '@refinedev/mui';

import { Route, Outlet, useLocation } from "react-router-dom";
import { NavigateToResource, CatchAllNavigate } from "@refinedev/react-router-v6";

// Page components
import { Header } from "components/header";
import { Slider } from "components/slider";


// Pages for tentant

import { DashboardScreen } from "pages/dashboard";
import { NewLodgement } from 'pages/lodge-new-package';
import PluginPage from "pages/plugins";
import PackagePage from "pages/packages";
import { LodgedPackages } from "pages/lodged-packages";

import { BlogPostList, BlogPostCreate, BlogPostEdit, BlogPostShow } from "pages/blog-posts";
import { CategoryList, CategoryCreate, CategoryEdit, CategoryShow } from "pages/categories";


// Pages for admin

import  CreateNewTentant  from "pages/admin-create-tentant";
import { ListTeams, ShowTeam } from "pages/admin-teams";




export const CurrentRoutes = ({tentant = ''}) => {

    if (window.location.pathname === '/') {

        return( null )

    } else 

    if (tentant === 'Admin') {

        return (
                <Route
                    element={
                        <Authenticated
                            fallback={<CatchAllNavigate to="/login" />}
                        >
                                <ThemedLayoutV2
                                    Header={Header}
                                    Sider={Slider}
                                >
                                    <Outlet />
                                </ThemedLayoutV2>
                        </Authenticated>
                    }
                >
                    <Route index element={
                            <NavigateToResource resource="dashboard" />
                    } />
                    <Route path={tentant + '/dashboard'}>
                        <Route index element={<p>admin dashboard</p>} />
                    </Route>
                    <Route path={"/Admin/tentant"}>
                        <Route index element={<CreateNewTentant />} />
                    </Route>
                    <Route path={tentant + "/teams"}>
                        <Route index element={<ListTeams />} />
                        <Route path="show/:id" element={<ShowTeam />} />
                    </Route>
                </Route>
        );
        
    } else {

    return(
        
        
            <Route
                element={
                    <Authenticated
                        fallback={<CatchAllNavigate to="/login" />}
                    >
                            <ThemedLayoutV2
                                Header={Header}
                                Sider={Slider}
                            >
                                <Outlet />
                            </ThemedLayoutV2>
                    </Authenticated>
                }
            >
                <Route index element={
                        <NavigateToResource resource="dashboard" />
                } />
                <Route path={tentant + '/dashboard'}>
                    <Route index element={<DashboardScreen />} />
                </Route>
                <Route path={tentant + "/blog-posts"}>
                    <Route index element={<BlogPostList />} />
                    <Route path="create" element={<BlogPostCreate />} />
                    <Route path="edit/:id" element={<BlogPostEdit />} />
                    <Route path="show/:id" element={<BlogPostShow />} />
                </Route>
                <Route path={tentant + "/categories"}>
                    <Route index element={<CategoryList />} />
                    <Route path="create" element={<CategoryCreate />} />
                    <Route path="edit/:id" element={<CategoryEdit />} />
                    <Route path="show/:id" element={<CategoryShow />} />
                </Route>
                <Route path={tentant + "/lodgement"}>
                    <Route index element={<NewLodgement />} />
                    <Route path="show/:id" element={<CategoryShow />} />
                </Route>
                <Route path={tentant + "/lodgements"}>
                    <Route index element={<LodgedPackages />} />
                </Route>
                <Route path={tentant + "/plugins"}>
                    <Route index element={<PluginPage />} />
                </Route>
                <Route path={tentant + "/packages"}>
                    <Route index element={<PackagePage />} />
                </Route>
            </Route>
    );

    }
};