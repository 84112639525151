import { useEffect, useState } from "react";

import { Box, IconButton, Menu, MenuItem, Select } from "@mui/material";
import { green, red, grey } from '@mui/material/colors';
import PrintIcon from '@mui/icons-material/Print';
import { useLocation } from "react-router-dom";



const ZPL_Printer = (props: any) => {

    const [printerSelected, setPrinterSelected] = useState('');
    const [printerColor, setPrinterColor] = useState<any>(grey[900]);
    const [printerConnected, setPrinterConnected] = useState(false);
    const [printerListData, setPrinterListData] = useState<string[]>([]);
    const [printDeviceSelected, setPrintDeviceSelected] = useState('');

    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const location = useLocation();
    const qz = require("qz-tray")

    useEffect(() => {

        if (qz.websocket.isActive()) {
            setPrinterColor(green[900])

        } else {
            setPrinterColor(red[900])
            console.log('Printer not connected, attempting to connect')
            qz.websocket.connect().then(() => {setPrinterColor(green[900])})
        }

    }, [location])


    //Handle printer color by checking if the printer is connected
    useEffect(() => {

        printerList()

        if (qz.websocket.isActive()) {
            setPrinterColor(green[900])

        } else {
            setPrinterColor(red[900])
        }

    }, [printerConnected])

    //Handle on load printer lookup
    useEffect(() => {

        const currentPrinterType = getPrinterType()

        if (currentPrinterType == null) {
            localStorage.setItem('printer_type', 'zpl')
            setPrinterSelected('zpl')
            printerConnect()
        }

        if (currentPrinterType == 'pdf') {
            setPrinterColor(red[900])
            setPrinterSelected('pdf')
        }

        if (currentPrinterType == 'zpl') {
            printerConnect()
            setPrintDeviceSelected(getPrinterSelected() as string)
            setPrinterListData([getPrinterSelected() as string])
        }


    }, [])

    //Handle when new printer selected from menu
    useEffect(() => {

        if (printerSelected == 'pdf') {
            setPrinterColor(red[900])
        }

        if (printerSelected == 'zpl') {
            printerConnect()
        }

    }, [printerSelected])

    //Handle qz tray connections
    const printerConnect = () => {
        qz.websocket.connect().then(function (e: any) { setPrinterConnected(true) }).catch(function (e: any) { setPrinterConnected(false) })
    }

    const getPrinterType = () => {
        var token
        if (typeof window !== 'undefined') {
            token = localStorage.getItem('printer_type')
        } return token
    }

    const getPrinterSelected = () => {
        var token
        if (typeof window !== 'undefined') {
            token = localStorage.getItem('printer_selected')
        } return token
    }

    const printerList = () => {
        qz.printers.find().then(function (data: any) {
            var printerlist = ''
            for (var i = 0; i < data.length; i++) {
                printerlist += data[i] + ','
            }
            
            setPrinterListData(printerlist.split(','))
        }).catch(function (e: any) { console.log(e) })
    }


    //Finish qz tray connections

    //Handle menu close logic
    const handleClose = (e: any) => {

        if ((e.target.innerText) === 'Zebra Printer') {
            localStorage.setItem('printer_type', 'zpl')
            setPrinterConnected(false)
            setPrinterSelected('zpl')
        }

        if ((e.target.innerText) === 'PDF Download') {
            localStorage.setItem('printer_type', 'pdf')
            setPrinterSelected('pdf')
        }

        setMenuOpen(false);
    };

    const handlePrinterSelect = (e: any) => {
        setPrintDeviceSelected(e.target.value)
        localStorage.setItem('printer_selected', e.target.value)
    }

    return (
        <Box sx={{background: 'background.paper'}}>
            <IconButton onClick={(event) => { setAnchorEl(event.currentTarget); setMenuOpen(true) }} id="zpl-button" style={{ color: printerColor }} >
                <PrintIcon />
            </IconButton>
            <Menu
                id="zpl-printer-menu"
                aria-labelledby="zpl-printer-button"
                anchorEl={anchorEl}
                MenuListProps={{
                    'aria-labelledby': 'zpl-button',
                }}
                open={menuOpen}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={(e) => handleClose(e)}>Zebra Printer</MenuItem>
                <MenuItem onClick={(e) => printerList()}>Zebra Printer List</MenuItem>
                <MenuItem onClick={(e) => handleClose(e)}>PDF Download</MenuItem>
                <MenuItem onClick={() => setMenuOpen(false)}>Close</MenuItem>
            </Menu>
            <Select
                variant="filled"
                labelId="printer-select"
                id="printer-select"
                sx={{background: 'background.paper', backgroundColor: 'rgba(255, 255, 255, 0.00)', height: '45px', width: '250px', marginbottom: '10px', boxShadow: 'none', borderWidth: '0px' }}
                disableUnderline={true}
                value={printDeviceSelected}
                onChange={(e) => handlePrinterSelect(e)}
                inputProps={{ IconComponent: () => null }}
            >
                {printerListData.length == 0 && <MenuItem value={printDeviceSelected}>Loading, please wait</MenuItem>}
                {printerListData.map((printer) => (
                    <MenuItem key={printer} value={printer}>{printer}</MenuItem>
                ))}
            </Select>
        </Box>
    )
}

export default ZPL_Printer;