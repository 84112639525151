import React, { useEffect, useState, useRef } from 'react';
import useScanDetection from 'use-scan-detection';

interface BarcodeScannerProps {
  setOrderID: (data: string) => void;
}

const BarcodeScanner: React.FC<BarcodeScannerProps> = ({ setOrderID }) => {

  const [barcodeScanned, setBarcodeScanned] = useState(false);
  const lastKeyDownTimestamp = useRef<number>(0);

  //Barcode helper, stops barcode scans overwritting form fields
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const currentTimestamp = new Date().getTime();

      if (event.key) {
        const timeElapsed = currentTimestamp - lastKeyDownTimestamp.current;

        if (timeElapsed < 40) {
          // Input likely from barcode scanner
          event.preventDefault();
        }
      }

      lastKeyDownTimestamp.current = currentTimestamp;
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  useScanDetection({
    onComplete: (code) => {
      setOrderID(code as string)
      setBarcodeScanned(true)
    },
    minLength: 5,
    startCharacter: [79],
    endCharacter: [190]
  })

  return (
    null
  );
};



export default BarcodeScanner;
