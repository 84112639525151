import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { TextField, IconButton, Box, CircularProgress, Backdrop } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { ActionButton } from "components/muiButton";
import { TeamList, MemberList } from "interfaces";
import { teams, functions } from "utility";

export const ShowTeam = () => {

    const { id = '' } = useParams();

    const [teamList, setTeamList] = useState<TeamList>()
    const [memberList, setMemberList] = useState<MemberList[]>([])
    const [loading, setLoading] = useState<boolean>(false);

    //Set columns for MUI Data Grid
    const columns: GridColDef[] = [
        {
            field: 'userEmail',
            headerName: 'User ID',
            flex: 1,
        },
        {
            field: 'roles',
            headerName: 'Role ID',
            flex: 1,
        },
    ];

    //Set state for form
    interface Name {
        id: number;
        value: string;
        roles: string;
        error: string;
    }

    //Start Form functions

    const [names, setNames] = useState<Name[]>([
        { id: 1, value: '', roles: '', error: '' }
    ]);

    const handleNameChange = (id: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        let error = '';

        // Validate the input as an email address
        if (name === 'value' && value.trim().length && !/\S+@\S+\.\S+/.test(value.trim())) {
            error = 'Please enter a valid email address';
        }

        setNames(prevNames => {
            const index = prevNames.findIndex(name => name.id === id);

            if (index !== -1) {
                return [
                    ...prevNames.slice(0, index),
                    { ...prevNames[index], [name]: value, error },
                    ...prevNames.slice(index + 1)
                ];
            }

            return prevNames;
        });
    };

    const handleAddClick = () => {
        const newId = names.length + 1;
        setNames(prevNames => [
            ...prevNames,
            { id: newId, value: '', roles: '', error: '' }
        ]);
    };

    const handleRemoveClick = (id: number) => {
        setNames(prevNames => prevNames.filter(name => name.id !== id));
    };

    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // Check if any names are empty or invalid
        const hasErrors = names.some(name => !name.value.trim() || name.error);

        if (hasErrors) {
            alert('Please fill in all required fields correctly');
            return;
        }

        const namesObj = names.reduce((obj, name) => {
            return { ...obj, [name.id]: { value: name.value, roles: name.roles } };
        }, {});

        console.log('{"tentant": "' + id + '","create": ' + JSON.stringify(namesObj) +'}' );

        try {
            setLoading(true);

            // Call the Appwrite function to create the team
            const response = await functions.createExecution(
                'createTeam','{"create": ' + JSON.stringify(namesObj) + '}'
            );

            // Reset the form and display a success message
            setNames([{ id: 1, value: '', roles: '', error: '' }]);
            alert(`Team created with ID ${response.$id}`);

        } catch (error) {
            console.error(error);
            alert('An error occurred while creating the team');
        } finally {
            setLoading(false);
        }
    };

    //End Form functions

    //Get memberships from Appwrite
    useEffect(() => {
        teams.get(id)
            .then((response) => {
                setTeamList(response);
            })
            .catch((error) => {
                console.error(error);
            });

        teams.listMemberships(id)
            .then((response) => {
                console.log(response);
                setMemberList(response.memberships);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [id]);

    return (
        <Box sx={{ position: 'relative' }}>
            <div style={{ height: 400, width: '100%' }}>
                <h1>{teamList?.name}</h1>
                <h2>Total Users is {teamList?.total}</h2>

                <DataGrid 
                    getRowId={(row) => row.$id}
                    rows={memberList}
                    columns={columns}
                    pageSizeOptions={[5]}
                    disableRowSelectionOnClick
                />

                <div style={{ display: 'flex', marginTop: 24 }}>
                    <form onSubmit={handleFormSubmit}>
                        {names.map((name) => (
                            <div key={name.id} style={{ marginTop: 24 }}>
                                <TextField
                                    style={{marginRight: 10}}
                                    label={`Email ${name.id}`}
                                    name="value"
                                    value={name.value}
                                    onChange={(event) => handleNameChange(name.id, event)}
                                    error={!!name.error}
                                    helperText={name.error}
                                />
                                <TextField
                                    label={`Roles for ${name.id}`}
                                    name="roles"
                                    value={name.roles}
                                    onChange={(event) => handleNameChange(name.id, event)}
                                />
                                {names.length > 1 && (
                                    <IconButton onClick={() => handleRemoveClick(name.id)}>
                                        <RemoveIcon />
                                    </IconButton>
                                )}
                                {name.id === names.length && (
                                    <IconButton onClick={handleAddClick}>
                                        <AddIcon />
                                    </IconButton>
                                )}
                            </div>
                        ))}
                        <ActionButton type="submit">Add Team Members</ActionButton>
                    </form>
                </div>
            </div>
            <Backdrop open={loading} sx={{ zIndex: 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};
