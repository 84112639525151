import React, { useEffect, useState, useContext } from 'react';
import { ID } from '@refinedev/appwrite';
import { database } from 'utility/appwriteClient';
import { TentantContext } from "contexts/tentant";

import {
    TextField,
    Button,
    Grid,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Box,
} from '@mui/material';


interface Package {
    $id: string;
    Package_Name: string;
    Package_Type: string;
    Package_Length: number;
    Package_Width: number;
    Package_Height: number;
}

const PackagePage: React.FC = () => {
    const [packages, setPackages] = useState<Package[]>([]);
    const [editMode, setEditMode] = useState<{ [key: string]: boolean }>({});
    const [modifiedPackages, setModifiedPackages] = useState<{ [key: string]: Package }>({});
    const [newPackage, setNewPackage] = useState<Package>({
        $id: '',
        Package_Type: '',
        Package_Name: '',
        Package_Length: 0,
        Package_Width: 0,
        Package_Height: 0,
    });
    const [isPackageValid, setIsPackageValid] = useState(false);

    const [tentant] = useContext(TentantContext);

    useEffect(() => {
        fetchPackages();
    }, []);

    const fetchPackages = async () => {

        try {
            const response = await database.listDocuments(tentant.toLowerCase(), tentant.toLowerCase() + '_preset');
            setPackages(response.documents as unknown as Package[]);
        } catch (error) {
            console.error('Error fetching packages:', error);
        }
    };

    const handleEditToggle = (packageId: string) => {
        setEditMode((prevEditMode) => ({
            ...prevEditMode,
            [packageId]: !prevEditMode[packageId],
        }));
    };

    const handlePackageFieldChange = (packageId: string, field: keyof Package, value: string) => {
        setModifiedPackages((prevModifiedPackages) => ({
            ...prevModifiedPackages,
            [packageId]: {
                ...prevModifiedPackages[packageId],
                [field]: value,
            },
        }));
    };

    const handleSave = async () => {

        try {
            const promises = Object.entries(modifiedPackages).map(([packageId, modifiedPackage]) => {
                console.log(JSON.stringify(modifiedPackage))
                // Update an existing package
                return database.updateDocument(tentant.toLowerCase(), tentant.toLowerCase() + '_preset', packageId, JSON.stringify(modifiedPackage))
            });

            await Promise.all(promises);
            setModifiedPackages({});
            setEditMode({});
            setNewPackage({
                $id: '',
                Package_Name: '',
                Package_Type: '',
                Package_Length: 0,
                Package_Width: 0,
                Package_Height: 0,
            });
            fetchPackages();
        } catch (error) {
            console.error('Error saving packages:', error);
        }
    };

    const handleAddPackage = async () => {

        try {
            await database.createDocument(tentant.toLowerCase(), tentant.toLowerCase() + '_preset', ID.unique(),
                '{"Package_Name": "' + newPackage.Package_Name + '", "Package_Type": "' + newPackage.Package_Type + '", "Package_Width": "' + newPackage.Package_Width + '", "Package_Length": "' + newPackage.Package_Length + '", "Package_Height": "' + newPackage.Package_Height + '"}')
            setNewPackage({
                $id: '',
                Package_Name: '',
                Package_Type: '',
                Package_Length: 0,
                Package_Width: 0,
                Package_Height: 0,
            });
            setIsPackageValid(false);
            fetchPackages();
        } catch (error) {
            console.error('Error saving packages:', error);
        }

    };

    const validatePackage = () => {
        if (
            newPackage.Package_Name.trim() === '' ||
            newPackage.Package_Type.trim() === '' ||
            newPackage.Package_Length === 0 ||
            newPackage.Package_Width === 0 ||
            newPackage.Package_Height === 0
        ) {
            setIsPackageValid(false);
        } else {
            setIsPackageValid(true);
        }
    };

    useEffect(() => {
        validatePackage();
    }, [newPackage]);

    return (

        <Box sx={{ p: 4, borderRadius: 4, backgroundColor: 'background.paper' }}>

            <h1>Edit Standard Packages</h1>

            <Grid container spacing={4} alignItems="center" sx={{ marginTop: '10px', marginBottom: '20px' }}>
                <Grid item sm={2}>
                    <TextField
                        label="Name"
                        value={newPackage.Package_Name}
                        onChange={(e) => setNewPackage({ ...newPackage, Package_Name: e.target.value })}
                    />
                </Grid>
                <Grid item sm={2}>
                    <TextField
                        label="Type"
                        value={newPackage.Package_Type}
                        onChange={(e) => setNewPackage({ ...newPackage, Package_Type: e.target.value })}
                    />
                </Grid>
                <Grid item sm={2}>
                    <TextField
                        label="Length"
                        type="number"
                        value={newPackage.Package_Length}
                        onChange={(e) => setNewPackage({ ...newPackage, Package_Length: Number(e.target.value) })}
                    />
                </Grid>
                <Grid item sm={2}>
                    <TextField
                        label="Width"
                        type="number"
                        value={newPackage.Package_Width}
                        onChange={(e) => setNewPackage({ ...newPackage, Package_Width: Number(e.target.value) })}
                    />
                </Grid>
                <Grid item sm={2}>
                    <TextField
                        label="Height"
                        type="number"
                        value={newPackage.Package_Height}
                        onChange={(e) => setNewPackage({ ...newPackage, Package_Height: Number(e.target.value) })}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleAddPackage} disabled={!isPackageValid}>
                        Add
                    </Button>
                </Grid>
            </Grid>


            <TableContainer>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Length</TableCell>
                            <TableCell>Width</TableCell>
                            <TableCell>Height</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {packages.map((pkg) => (
                            <TableRow key={pkg.$id}>
                                <TableCell>{pkg.Package_Name}</TableCell>
                                <TableCell>{pkg.Package_Type}</TableCell>
                                <TableCell>
                                    {editMode[pkg.$id] ? (
                                        <TextField
                                            value={modifiedPackages[pkg.$id]?.Package_Length || pkg.Package_Length}
                                            onChange={(e) => handlePackageFieldChange(pkg.$id, 'Package_Length', e.target.value)}
                                        />
                                    ) : (
                                        pkg.Package_Length
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editMode[pkg.$id] ? (
                                        <TextField
                                            value={modifiedPackages[pkg.$id]?.Package_Width || pkg.Package_Width}
                                            onChange={(e) => handlePackageFieldChange(pkg.$id, 'Package_Width', e.target.value)}
                                        />
                                    ) : (
                                        pkg.Package_Width
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editMode[pkg.$id] ? (
                                        <TextField
                                            value={modifiedPackages[pkg.$id]?.Package_Height || pkg.Package_Height}
                                            onChange={(e) => handlePackageFieldChange(pkg.$id, 'Package_Height', e.target.value)}
                                        />
                                    ) : (
                                        pkg.Package_Height
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    <Button
                                        variant="outlined"
                                        color={editMode[pkg.$id] ? 'secondary' : 'primary'}
                                        onClick={() => handleEditToggle(pkg.$id)}
                                    >
                                        {editMode[pkg.$id] ? 'Cancel' : 'Edit'}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Grid container spacing={2} sx={{ paddingTop: '20px' }}>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save Changes and Update Packages
                    </Button>
                </Grid>
            </Grid>

        </Box>
    );
};

export default PackagePage;