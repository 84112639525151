import * as React from 'react';
import { Refine, Authenticated } from '@refinedev/core';
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import { ThemedLayoutV2,notificationProvider,RefineSnackbarProvider } from '@refinedev/mui';

import { dataProvider, liveProvider } from "@refinedev/appwrite";
import { CssBaseline, GlobalStyles } from "@mui/material";
import { BrowserRouter, Route, Routes, Outlet, Navigate  } from "react-router-dom";
import routerBindings, { NavigateToResource, UnsavedChangesNotifier } from "@refinedev/react-router-v6";
import { appwriteClient } from "utility";

import { ColorModeContextProvider } from "./contexts/color-mode";
import { TentantProvider } from 'contexts/tentant';


import { Header } from "./components";
import { Slider } from "components/slider";

import { authProvider, CheckTentant } from "./authProvider";
import { Resources } from './constants/variables'
import { CurrentRoutes } from "./pages/routes";

import { AuthPage } from "pages/login";
import  SignInSide  from "pages/misLogin";
import  IndexPage  from "pages/index";


function App() {

    const [currentTentant, setCurrentTentant] = React.useState('');
    
    return (
        <BrowserRouter>
        <TentantProvider>
        <CheckTentant updateTentant={setCurrentTentant}/>
        <RefineKbarProvider>
        <ColorModeContextProvider>
        <CssBaseline />
        <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
        <RefineSnackbarProvider>
        <Refine dataProvider={dataProvider(appwriteClient, {
                databaseId: "database",
            })}
            liveProvider={liveProvider(appwriteClient, {
                databaseId: "database",
            })}
            authProvider={authProvider}
            notificationProvider={notificationProvider}
            routerProvider={routerBindings} 
            resources={Resources({tentant: currentTentant})}
            options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
            }}
        >
                
            <Routes>
                
                {CurrentRoutes({tentant: currentTentant})}

                <Route
                    element={
                        <Authenticated fallback={<Outlet />}>
                            <NavigateToResource />
                        </Authenticated>
                    }
                >
                    <Route
                        path="/"
                        element={
                        <IndexPage />
                        }
                    />
                    <Route
                        path="/login"
                        element={
                        <AuthPage type="login" rememberMe={false} registerLink={false}
                        />
                        }
                    />
                    <Route
                        path="/mislogin"
                        element={<SignInSide />}
                    />
                </Route>
                <Route
                    element={
                        <Authenticated>
                            <ThemedLayoutV2
                                Header={Header}
                                Sider={Slider}
                            >
                                <Outlet />
                            </ThemedLayoutV2>
                        </Authenticated>
                    }
                >
                    {/* {currentTentant == '' ?
                        <Route path="*" element={<Navigate to={'/'+currentTentant} replace />} /> :
                        <Route path="*" element={<Navigate to={'/'+currentTentant+'/dashboard'} replace />} />
                    } */}

                    <Route path="*" element={currentTentant === '' ? null : <Navigate to={'/'+currentTentant+'/dashboard'} replace />} />

                </Route>
            </Routes>


                <RefineKbar />
                <UnsavedChangesNotifier />
            </Refine>
            </RefineSnackbarProvider>


        </ColorModeContextProvider>
        </RefineKbarProvider>
        </TentantProvider>
        </BrowserRouter>
      );
};

export default App;