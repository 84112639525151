import { useState, useContext } from 'react';
import { database } from 'utility/appwriteClient';
import { PluginList } from 'interfaces';
import { TentantContext } from "contexts/tentant";
import { Card, CardHeader, Grid } from "@mui/material";

export const PluginItem: React.FC<{ plugin: PluginList }> = ({ plugin }) => {

    const [tentant] = useContext(TentantContext);

    const [isEnabled, setIsEnabled] = useState(plugin.enabled?.toLowerCase?.() === 'true');
  
    const handleToggle = () => {
      setIsEnabled(!isEnabled);
  
      // Update the plugin status in the Appwrite database
      database.updateDocument(tentant.toLowerCase(), tentant.toLowerCase() + '_plugin', plugin.$id, '{"enabled": "'+!isEnabled+'"}')
        .catch((error) => {
          console.error(error);
        });
    };
  
    return (
      <div style={{
        width: '100%',
        paddingTop: '1px',
        padding: '10px',
        marginBottom: '10px',
        backgroundColor: "#f57c00",
        borderRadius: '10px',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
      }}>
        <h3>{plugin.Name}</h3>
        <p>{plugin.Desc}</p>
        <label>
          Plugin Enabled:
          <input
            type="checkbox"
            checked={isEnabled}
            onChange={handleToggle}
            disabled={true}
          />
        </label>
      </div>
    );
  };
  