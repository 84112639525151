import * as React from "react";
import { AuthPage as MUIAuthPage, AuthProps } from "@refinedev/mui";
import { useTheme } from "@mui/material/styles";

import { useRouterContext } from "@refinedev/core";

import { account } from "utility";
import { title } from "process";

const authWrapperProps = {
    style: {
        background:
            "radial-gradient(50% 50% at 50% 50%,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, 0.3) 100%),url('images/login/login-bg.png')",
        backgroundSize: "cover",
    },
};


const RenderAuthContent = (content: React.ReactNode) => {
    const { Link } = useRouterContext();
    const { palette } = useTheme();

    const handleAuth0click = () => {
        account.createOAuth2Session('auth0', 'https://shipd.com.au/tentant/', 'https://shipd.com.au/')
      };

    return (
        <div
            style={{
                margin: "-150px",
                paddingTop: "50px",
                paddingLeft: "70px",
                paddingRight: "70px",
                background:  palette.mode === "dark" ? 
                "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #121212" : 
                "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #ffffff",
                borderRadius: "10%"
            }}
        >
            <div
            style={{
                display: "flex",
                justifyContent: "center",
            }}>
                    <img
                        src= {palette.mode === "dark" ? "/images/shipd-white-logo.png": "/images/shipd-black-logo.png"}
                        alt="ShipD Logo"
                        style={{ height: "100px", marginBottom: 26 }}
                    />
            </div>

            <div
                style={{
                    width: 400,
                    marginTop: 60,
                    marginBottom: 140,
                    padding: 5,
                }}
            >
                <a
                    style={{
                        cursor: "pointer",
                        display: "block",
                        border: "1px solid #FFA726",
                        textAlign: "center",
                        textDecoration: "none",
                        color: "#FFA726",
                        width: "90%",
                        marginLeft: "5%",
                        marginTop: 5,
                        marginBottom: 20,
                        padding: 5,
                        paddingBottom: 5,
                    }}
                    onClick={handleAuth0click}>Sign In With Auth0</a>

                <a
                    style={{
                        display: "block",
                        border: "1px solid #FFA726",
                        textAlign: "center",
                        textDecoration: "none",
                        color: "#FFA726",
                        width: "90%",
                        marginLeft: "5%",
                        marginTop: 5,
                        marginBottom: 20,
                        padding: 5,
                        paddingBottom: 5,
                    }}
                    href="/mislogin">Sign In With MIS</a>

            </div>
            {/* {content} */}
        </div>
    );
};

export const AuthPage: React.FC<AuthProps> = ({ type, formProps }) => {
    return (
        <MUIAuthPage
            type={type}
            wrapperProps={authWrapperProps}
            renderContent={RenderAuthContent}
            formProps={formProps}
            contentProps={{
                style: {
                    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.25)",
                }
            }}
        />
    );
};
