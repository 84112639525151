import { Account, Appwrite, Storage, Teams, Functions, Databases } from "@refinedev/appwrite";


//enviroment variables
//const APPWRITE_URL = "http://172.16.90.4/v1";
const APPWRITE_URL = "https://shipd.com.au/v1";
//const APPWRITE_PROJECT = "6521d7139d209fdb4743";
const APPWRITE_PROJECT = "SHIPD-Client";


//Start client and services
const appwriteClient = new Appwrite();


appwriteClient.setEndpoint(APPWRITE_URL).setProject(APPWRITE_PROJECT);
const account = new Account(appwriteClient);


const storage = new Storage(appwriteClient);
const teams = new Teams(appwriteClient);
const functions = new Functions(appwriteClient);
const database = new Databases(appwriteClient);

export { appwriteClient, account, storage, teams, functions, database };
