import { Button, Grid, LinearProgress, Typography } from '@mui/material';
import { DataGrid, GridFilterModel } from "@mui/x-data-grid";
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';

import React, { useState, useEffect, useContext } from 'react';

import { database, functions } from 'utility/appwriteClient';
import { TentantContext } from "contexts/tentant";
import { ID, Query } from '@refinedev/appwrite';

import printLabelLogic from 'components/Plugins/labels/printLabels'

import { preferredLogic, addOnLogic } from 'components/pluginsMIS/lodge-new-package/shippingPreferred';
import { misLabelLogic } from 'components/pluginsMIS/labels/printLabelsMIS';
import { misOrderLodge } from 'components/pluginsMIS/lodge-new-package/orderLodgement';
import { SynergyComplete } from 'components/pluginSynergy/lodge-new-package/orderLodgement';
import { resolve } from 'dns';
import { set } from 'react-hook-form';
import { LodgedPackages } from 'pages/lodged-packages';

interface NewLodgementFormProps {
    loading: any;
    setPackages: any;
    packageData: any;
    getEstimates: any;
    linkedOrders: any;
    plugins: any;
}

const PackageLodgementScreen: React.FC<NewLodgementFormProps> = ({ loading, setPackages, packageData, getEstimates, linkedOrders, plugins }) => {

    const [tentant] = useContext(TentantContext);
    const [returnButton, setReturnButton] = useState(false);

    const [estimates, setEstimates] = useState<any>({});
    const [estimateData, setEstimateData] = useState<any>({});

    const [errors, setErrors] = useState<any>({});
    const [errorData, setErrorData] = useState<any>({});

    const [preferred, setPreferred] = useState<any>({});
    const [preferredData, setPreferredData] = useState<any>({});

    const [addOn, setAddOn] = useState<any>({});
    const [addOnData, setAddOnData] = useState<any>({});

    const [submitted, setSubmitted] = useState<any>([]);
    const [submittedData, setSubmittedData] = useState<any>([]);

    const qz = require("qz-tray")


    useEffect(() => {
        setEstimateData({ ...estimateData, ...estimates })
    }, [estimates]);

    useEffect(() => {
        setErrorData({ ...errorData, ...errors })
    }, [errors]);

    useEffect(() => {
        setPreferredData({ ...preferredData, ...preferred })
    }, [preferred]);

    useEffect(() => {
        setAddOnData({ ...addOnData, ...addOn })
    }, [addOn]);

    useEffect(() => {
        setSubmittedData([...submittedData, ...submitted])
    }, [submitted]);

    useEffect(() => {

        if (packageData.length > 0) {

            handleSubmitEstimate()
        }

    }, [packageData]);

    const [filterModel, setFilterModel] = React.useState<GridFilterModel>();

    const handleSubmitEstimate = async () => {

        if (packageData.length > 0 && returnButton === false) {

            const startEstimate = await Promise.all(
                packageData.map(async (item: any, index: any) => {
                    console.log(item)
                    const loadPackages = await performAsyncOperation(item, index);

                    return loadPackages;
                })
            );

        } else {
            getEstimates(false)
        }

    };

    function performAsyncOperation(item: any, packageIndex: any): Promise<any> {

        console.log(item)

        return new Promise(async (resolve) => {

            let stateSet = ''

            if (item.state == "INT") {
                stateSet = item.intState
            } else { 
                stateSet = item.state 
            }

            const newDocument = await database.createDocument(tentant.toLowerCase(), tentant.toLowerCase() + '_package', ID.unique(),
                '{"Customer_Name": "' + item.shipTo +
                '", "Customer_Email": "' + item.email +
                '", "Customer_Phone": "' + item.phone +
                '", "Address_Line_One": "' + item.address1 +
                '", "Address_Line_Two": "' + item.address2 +
                '", "Address_City": "' + item.city +
                '", "Address_Postcode": "' + item.postcode +
                '", "Address_State": "' + stateSet +
                '", "Address_Country": "' + item.country +
                '", "Ship_From": "' + item.shipFrom +
                '", "Order_Reference": "' + item.orderId +
                '", "Shipment_Leave": "' + item.leave +
                '", "Shipment_Instructions": "' + item.instructions +
                '", "Extra_One": "' + item.extraOne +
                '", "Extra_Two": "' + item.intDesc +
                '", "Extra_Three": "' + item.taxID +
                '", "Extra_Four": "' + item.incoterm +
                '", "Extra_Five": "' + item.exportReason +
                '", "created": "' + new Date().toISOString() +
                '", "Status": "Estimate' +
                '"}')

            let itemArray: any = []

            await Promise.all(
                item.items.map(async (newItems: any) => {
                    await database.createDocument(tentant.toLowerCase(), tentant.toLowerCase() + '_items', ID.unique(),
                        '{"Link": "' + newDocument.$id +
                        '", "Package_Name": "' + newItems.Package_Name +
                        '", "Package_Type": "' + newItems.Package_Type +
                        '", "Package_Length": "' + newItems.Package_Length +
                        '", "Package_Width": "' + newItems.Package_Width +
                        '", "Package_Height": "' + newItems.Package_Height +
                        '", "Package_Weight": "' + newItems.Package_Weight +
                        '", "Package_Quantity": "' + newItems.Package_Quantity +
                        '", "Item_Quantity": "' + newItems.Item_Quantity +
                        '"}').then((response) => { itemArray.push(response.$id) })

                }))


            const estimateRequest = await functions.createExecution('newEstimateRequest',
                '{"tentant": "' + tentant + '","package_id": "' + newDocument.$id + '"}'
            )

            const options = await database.listDocuments(tentant.toLowerCase(), tentant.toLowerCase() + '_options', [Query.equal("Link", [newDocument.$id])]);

            const newEstimates = Object.assign({});
            newEstimates[packageIndex] = options;
            setEstimates(newEstimates)

            const newErrors = Object.assign({});
            newErrors[packageIndex] = JSON.parse(estimateRequest.response).errors;
            setErrors(newErrors)

            const newPreferred = Object.assign({});
            newPreferred[packageIndex] = await preferredCheck(item)
            setPreferred(newPreferred)

            const newAddOn = Object.assign({});
            newAddOn[packageIndex] = await addOnCheck(item, '')
            setAddOn(newAddOn)

            if (JSON.parse(estimateRequest.response).errors != '') {
                await database.updateDocument(tentant.toLowerCase(), tentant.toLowerCase() + '_package', newDocument.$id, '{"Status": "Error", "Errors": "' + JSON.parse(estimateRequest.response).errors + '"}')
            }

            resolve(newDocument.$id + ' items ' + itemArray)
        })
    }

    const preferredCheck = (packageDetails: any) => {
        return new Promise(async (resolve) => {
            plugins.filter((item: any) => item.Type === 'Preferred').map((pluginDetail: any) => {
                if (pluginDetail.Name === 'MIS Logic') {
                    const preferred = preferredLogic(packageDetails, packageDetails.packageOrderData) as string

                    setFilterModel({items: [
                        { field: 'carrier', operator: 'isAnyOf', value: ['STARTRACK', ' Local Van'] },
                    ],})

                    resolve(preferred)
                }
            })
            resolve('')
        })
    }

    const addOnCheck = (packageDetails: any, packageMethod: any) => {
        return new Promise(async (resolve) => {
            plugins.filter((item: any) => item.Type === 'Preferred').map((pluginDetail: any) => {
                if (pluginDetail.Name === 'MIS Logic') {
                    const preferred = addOnLogic(packageDetails, packageDetails.packageOrderData, packageMethod) as unknown as string
                    resolve(preferred)
                }
            })
            resolve('')
        })
    }

    const labelCheck = (packageDetails: any, index: any, packageId: any) => {
        return new Promise(async (resolve) => {

            const databasePackage = await database.getDocument(tentant.toLowerCase(), tentant.toLowerCase() + '_package', packageId)

            plugins.filter((item: any) => item.Type === 'Preferred').map((pluginDetail: any) => {
                if (pluginDetail.Name === 'MIS Logic') {
                    const preferred = misLabelLogic(databasePackage, packageData[index].items, packageDetails.packageOrderData) as unknown as string
                    resolve(preferred)
                }
            })
            resolve('')
        })
    }

    const lodgementCheck = (packageId: any) => {
        return new Promise(async (resolve) => {

            plugins.filter((item: any) => item.Type === 'Preferred').map((pluginDetail: any) => {
                if (pluginDetail.Name === 'MIS Logic') {
                    const preferred = misOrderLodge(tentant, packageId) as unknown as string
                    resolve(preferred)
                }
                if (pluginDetail.Name === 'Synergy Complete') {
                    const preferred = SynergyComplete(tentant, packageId) as unknown as string
                    resolve(preferred)
                }
            })
            resolve('')
        })
    }

    const lodgeParcel = async (packageData: any, index: any, packageId: any, source: any, method: any) => {

        console.log('lodgeParcel', index, packageId, source, method)

        loading(true)

        const lodgeRequest = await functions.createExecution('newLodgementRequest',
            '{"tentant": "' + tentant + '","package_id": "' + packageId + '","shipment_method": "' + method + '","shipment_source": "' + source + '"}'
        )

        try {
            console.log(JSON.parse(lodgeRequest.response))
            
        } catch (e) {
    
            loading(false)
            alert('Lodgement Failed, please try again.')
    
        }

        if (method === 'STEFPP') {
            const newAddOn = Object.assign({});
            newAddOn[index] = await addOnCheck(packageData, method)
            setAddOn(newAddOn)
        }

        if (JSON.parse(lodgeRequest.response).errors != '') {
            await database.updateDocument(tentant.toLowerCase(), tentant.toLowerCase() + '_package', packageId, '{"Status": "Error", "Errors": "Lodgement Failed"}')
            const newEstimates = Object.assign({});
            newEstimates[index] = { 'message': 'Error', 'errorList': JSON.parse(lodgeRequest.response).errors }
            setEstimates(newEstimates)
        } else {
            await database.updateDocument(tentant.toLowerCase(), tentant.toLowerCase() + '_package', packageId, '{"Status": "Lodged"}')
            const newEstimates = Object.assign({});
            newEstimates[index] = { 'message': 'Lodged' }

            try{
                labelCheck(packageData, index, packageId)
            } catch (error) {
                console.log(error)
            }

            try{
                printLabelLogic(source, lodgeRequest)
            } catch (error) {
                newEstimates[index] = { 'message': 'Error Printing Label, retrying connection to QZ tray...' }
                setEstimates(newEstimates)
                qz.websocket.disconnect().then(() => { qz.websocket.connect() }).then(() => { printLabelLogic(source, lodgeRequest) }).then(() => { newEstimates[index] = { 'message': 'Label Printed' } })
            }

            lodgementCheck(packageId)

            setEstimates(newEstimates)

            setSubmitted([index])

        }

        loading(false)

    }

    const returnLogic = async () => {

        await new Promise(async (resolve) => {

            const updatedPackages = [...packageData];

            submittedData.map(async (item: any) => {

                updatedPackages.splice(item, 1);

            })

            await setPackages(updatedPackages);

            resolve('done')

        })

        getEstimates(false)

    }


    return (

        <Grid container spacing={2} sx={{ background: 'primary' }}>

            <Grid item sm={8}>
                <h2>Submit Packages</h2>
            </Grid>

            <Grid item sm={4}>
                <Button variant="contained" color="primary" sx={{ margin: "5px" }} onClick={async () => {setReturnButton(true); await returnLogic()}}>
                    Return To Packages
                </Button>
            </Grid>

            {packageData.map((packageData: any, index: any) => (

                <Grid item xs={12} sm={12} key={'grid' + index}>

                    <Typography variant="h6" color="text.secondary" key={'heading' + index}>
                        {'Package ' + (index + 1)}
                    </Typography>

                    <Typography variant="subtitle1" color="text.secondary" key={'subheading' + index}>
                        {packageData.shipTo + ' ' + packageData.address1 + ' ' + packageData.address2 + ' ' + packageData.city + ' ' + packageData.postcode + ' ' + packageData.state}
                    </Typography>

                    {errorData[index] &&
                        <Typography variant="subtitle1" color="error" key={'error' + index}>
                            {errorData[index]}
                        </Typography>}

                    {addOnData[index] &&
                        addOnData[index].map((addOn: any, addOnIndex: any) => (
                            <Typography variant="subtitle1" color="error" key={'addon' + addOnIndex}>
                                {addOn.Name + ' Label Required.'}
                            </Typography>
                        ))
                    }

                    {!estimateData[index] && <LinearProgress />}

                    {estimateData[index] && estimateData[index].message != undefined &&
                        <Typography variant="subtitle1" color="text.secondary" key={'lodged' + index}>
                            {'Package ' + (index + 1) + ' ' + estimateData[index].message}
                        </Typography>
                    }

                    {estimateData[index] && estimateData[index].message === 'Error' &&
                        <Typography variant="subtitle1" color="text.secondary" key={'lodged' + index}>
                            {estimateData[index].errorList}
                        </Typography>
                    }

                    {estimateData[index] && estimateData[index].documents &&
                        <DataGrid
                            key={'datagrid' + index}
                            sx={{ border: '1', marginTop: '20px' }}
                            autoHeight
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            rows={
                                estimateData[index]?.documents.map((document: any) => ({
                                    id: document.$id,
                                    linkId: document.Link,
                                    method: document.Option_Method,
                                    carrier: document.Option_Carrier,
                                    service: document.Option_Service,
                                    price: document.Option_Price,
                                    date: document.Option_Estimate_Date,
                                    source: document.Option_Source,
                                }))
                            }
                            columns={
                                [
                                    { field: 'carrier', headerName: 'Method', flex: 1 },
                                    { field: 'service', headerName: 'Service', flex: 1 },
                                    { field: 'price', headerName: 'Price', flex: 1 },
                                    { field: 'date', headerName: 'Est. Delivery', flex: 1 },
                                    {
                                        field: "preferred",
                                        headerName: "Preferred",
                                        sortable: false,
                                        renderCell: (params) => {
                                            if (params.row.method === preferredData[index]) { return <WhereToVoteIcon /> };
                                        }
                                    },
                                    {
                                        field: "action",
                                        headerName: "",
                                        sortable: false,
                                        renderCell: (params) => {
                                            const onClick = (e: any) => {
                                                e.stopPropagation();
                                                if (params.row.method === 'Local') {
                                                    lodgeParcel(packageData, index, params.row.linkId, params.row.source, params.row.service)
                                                } else {
                                                    lodgeParcel(packageData, index, params.row.linkId, params.row.source, params.row.method)
                                                }
                                            };

                                            return <Button onClick={onClick}>Lodge</Button>;
                                        }
                                    }
                                ]
                            }
                            filterModel={filterModel}
                            onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                            hideFooter={true}
                        />
                    }

                </Grid>

            ))}

        </Grid>
    )
}

export default PackageLodgementScreen