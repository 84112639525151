import { useState } from 'react';
import { functions } from "utility";
import { TextField, Button, Box, CircularProgress, Backdrop } from '@mui/material';


const CreateNewTentant = () => {
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Validate the form input
    if (!name) {
      setNameError('Please enter a name');
      return;
    }

    try {
      setLoading(true);

      // Call the Appwrite function to create the tentant
      const response = await functions.createExecution(
        'newTentantRequest', '{"tentant": "' + name + '"}'     
      );

      console.log(response)

      // Reset the form and display a success message
      setName('');
      alert(`Tentant created with ID ${response.statusCode}`);
    } catch (error) {
      console.error(error);
      alert('An error occurred while creating the team');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box component="form" onSubmit={handleFormSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <TextField
          label="Tentant Name"
          value={name}
          onChange={handleNameChange}
          error={!!nameError}
          helperText={nameError}
        />
        <Button type="submit" variant="contained">Create New Tentant</Button>
      </Box>
      <Backdrop open={loading} sx={{ zIndex: 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default CreateNewTentant;
