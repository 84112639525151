import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { deepOrange } from '@mui/material/colors';
import { withTheme } from '@emotion/react';

export const ActionButton = styled(Button)<ButtonProps>(({ theme }) => ({    
    
    color: theme.palette.mode === 'dark' ? "white" : "white",
    backgroundColor: theme.palette.mode === 'dark' ? "" : deepOrange[400],
    borderColor: deepOrange[400],
    border: '1px solid',
    borderRadius: 2,
    height: 48,
    padding: '0 10px',
    boxShadow: 'none',
    margin: '24px 5px 15px',

    '&:hover': {
      borderColor: deepOrange[400],
      backgroundColor: deepOrange[700],
    },

  }));