import * as React from 'react';
import { AuthBindings, useIsAuthenticated } from "@refinedev/core";
import { AppwriteException } from "@refinedev/appwrite";
import { useLocation } from "react-router-dom";

import { TentantContext } from "contexts/tentant";
import { account, teams } from "./utility";

export const authProvider: AuthBindings = {
    login: async ({ email, password }) => {
        try {
            await account.createEmailSession(email, password);
            return {
                success: true,
                redirectTo: "/dashboard",
            };
        } catch (error) {
            const { type, message, code } = error as AppwriteException;
            return {
                success: false,
                error: {
                    message,
                    name: `${code} - ${type}`,
                },
            };
        }
    },
    logout: async () => {
        try {
            await account.deleteSession("current");
        } catch (error: any) {
            return {
                success: false,
                error,
            };
        }

        return {
            success: true,
            redirectTo: "/",
        };
    },
    onError: async (error) => {
        console.error(error);
        return { error };
    },
    check: async () => {

        try {
            const session = await account.get();

            if (session) {
                return {
                    authenticated: true,
                };
            }
        } catch (error: any) {
            return {
                authenticated: false,
                error: error,
                logout: true,
                redirectTo: "/login",
            };
        }

        return {
            authenticated: false,
            error: {
                message: "Check failed",
                name: "Session not found",
            },
            logout: true,
            redirectTo: "/login",
        };
    },
    getPermissions: async () => null,
    getIdentity: async () => {
        const user = await account.get();

        if (user) {
            return user;
        }

        return null;
    },
};

export const CheckTentant = (updateTentant: any) => {
    
    //Get Context
    const [tentant] = React.useContext(TentantContext);

    if (tentant != '') {

        //Get Teams Available
        var tentantList = [{$id:'',name:""}]
        var tentants = teams.list().then((response) => {const tentantList = response.teams})

        //Check if actual tentant is in list
        if (tentantList.filter(e => e.name === tentant).length === 0) {
            updateTentant.updateTentant(tentant.replace(/[^A-Z0-9]/ig, ""))

        } else {
        }

    }
    
    return null
}