import { useState } from 'react';
import { functions, account } from "utility";

import { Typography, TextField, Button, Grid, CssBaseline, Backdrop, CircularProgress,  } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((palette: any) => ({

  root: {
    height: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    background:
      palette.mode === "light"
        ? "radial-gradient(50% 50% at 50% 50%,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, 0.3) 100%),url('images/login/login-bg.png')"
        : "radial-gradient(50% 50% at 50% 50%,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, 0.3) 100%),url('images/login/login-bg.png')",

    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  size: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },

  paper: {
    margin: "30px 30px 60px 30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%",
    marginTop: 1
  },
  submit: {
    padding: "10px 0px 10px 0px",
    margin: "40px 0px 10px 0px"
  }
}));


const MISLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [loading, setLoading] = useState<boolean>(false);

  const { palette } = useTheme();
  const classes = useStyles(palette);


  const handelLogin = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    const response = await functions.createExecution(
      'misLoginHub', '{"misUser": "' + username + '", "misPassword": "' + password + '"}'
    );

    if (response.statusCode === 200 && JSON.parse(response.response).message == 200) {
      setErrorMessage('')
      try {
        await account.createEmailSession(username + '@cmykhub.com', JSON.parse(response.response).password);
        window.location.href = '/'
      } catch (error) {
        setErrorMessage('Server Error Try Again Later')
      }
    } else {
      setErrorMessage('Incorrect Username or Password')
    }

    setLoading(false)

  }


  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      {/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}

      <div
        style={{
          margin: "-150px",
          paddingTop: "50px",
          paddingLeft: "70px",
          paddingRight: "70px",
          background: palette.mode === "dark" ?
            "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #121212" :
            "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #ffffff",
          borderRadius: "10%"
        }}
      >
        <Backdrop open={loading} sx={{ zIndex: 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}>
          <img
            src={palette.mode === "dark" ? "/images/shipd-white-logo.png" : "/images/shipd-black-logo.png"}
            alt="ShipD Logo"
            style={{ height: "100px", marginBottom: 26 }}
          />
        </div>
        <div className={classes.paper}>

          <Typography component="h1" variant="h5">
            Sign in with MIS
          </Typography>
          <form noValidate className={classes.form}>
            <TextField
              onChange={(e) => setUsername(e.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoFocus
            />
            <TextField
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <p style={{ color: "red" }}>
              {errorMessage}
            </p>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handelLogin}
            >
              Sign In
            </Button>
          </form>
        </div>
      </div>
    </Grid>
  );
};

export default MISLogin;