import { useState } from 'react';
import { functions, account } from "utility";

import { Typography, AppBar, Toolbar, Grid, CssBaseline, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((palette: any) => ({

    root: {
        height: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        background: "radial-gradient(50% 50% at 50% 50%,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, 0.1) 100%),url('/images/index/ShipdBG.jpg')",

        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    size: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },

    paper: {
        margin: "30px 30px 60px 30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    form: {
        width: "100%",
        marginTop: 1
    },
    submit: {
        padding: "10px 0px 10px 0px",
        margin: "40px 0px 10px 0px"
    }
}));


const MISLogin = () => {
    const { palette } = useTheme();
    const classes = useStyles(palette);


    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />


            <div style={{ position: "fixed", top: 0 }}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100vw", paddingTop: "40px", paddingLeft: "200px", paddingRight: "200px" }}>
                    <img
                        src="/images/shipd-black-logo.png"
                        alt="Shipd Logo"
                        style={{ height: "60px", marginBottom: 26 }}
                    />
                    <Typography component="h1" variant="h6" noWrap style={{ paddingTop: "20px" }}>
                        <a style={{ paddingTop: "10px", textDecoration: "none", color: "#000000" }} href='/login'>
                            Log In
                        </a>
                    </Typography>
                </div>
            </div>



            <div style={{ display: "flex", justifyContent: "center", width: "100vw", paddingTop: "20px", paddingLeft: "100px", paddingRight: "100px" }}>
                <div style={{ width: "40vw", paddingTop: "20vh" }}>
                    <Typography component="h1" variant="h2" style={{ paddingBottom: "30px" }}>
                        Your trusted <b>shipping partner</b> for the print industry!
                    </Typography>
                    <Typography component="p">
                        We specialize in seamlessly integrating your print logistics, whether you're a commercial printer, print shop, or publishing house.
                        Our expertise in smart routing, advanced tracking, and cost efficient options ensures streamlined operations.
                    </Typography>
                    <Button style={{ marginTop: "30px", padding: "10px", width: "50%", background: "#d15f4e", color: "#FFFFFF", borderRadius: "100px" }}>
                    <a style={{ textDecoration: "none", color: "#FFFFFF" }} href='mailto:partner@dflow.com.au'> Contact Us </a>
                    </Button>
                </div>
                <img
                    src="/images/index/ShipdMan.svg"
                    alt="Shipd Logo"
                    style={{ width: "40vw", maxHeight: "800px" }}
                />
            </div>


        </Grid>
    );
};

export default MISLogin;