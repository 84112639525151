import { createContext, useState, useEffect } from "react";
import { teams } from "utility";

// eslint-disable-next-line
export const TentantContext = createContext<any[]>([]);

// eslint-disable-next-line
export const TentantProvider = (props: any) => {
    const [tentant, setTentant] = useState("");

    return <TentantContext.Provider value={[tentant, setTentant]} {...props} />;
};
