import { Item } from 'interfaces';

export function sortData(misData: any, shipFrom: any) {
    let packages = [];
    let newItem : Item;

    const quantity = misData.data.Multiple * misData.data.Quantity

    if (misData.data.Description.includes('Presentation')) {

        newItem = {
            id: String(Date.now()),
            type: '',
            Package_Name: 'A2',
            Package_Type: 'carton',
            Package_Length: 62,
            Package_Width: 42,
            Package_Height: 6,
            Package_Weight: 7,
            Package_Quantity: Math.ceil(quantity / 100),
            Item_Quantity: 100,
        };

    } else if (misData.data.Description.includes('Cards')) {
        
        const weight = (quantity:any) => {
                if (quantity < 251) { return 1}
                if (quantity < 501) { return 2}
                if (quantity < 1001) { return 3}
                if (quantity < 1501) { return 4}
                if (quantity > 1500) { return 5}
        }

        newItem = {
            id: String(Date.now()),
            type: '',
            Package_Name: 'BCBox',
            Package_Type: 'carton',
            Package_Length: 29,
            Package_Width: 19,
            Package_Height: 7,
            Package_Weight: weight(quantity) as number,
            Package_Quantity: 1,
            Item_Quantity: quantity,
        };

    } else {


        newItem = {
            id: String(Date.now()),
            type: '',
            Package_Name: 'A4',
            Package_Type: 'carton',
            Package_Length: 31,
            Package_Width: 23,
            Package_Height: 17,
            Package_Weight: 0,
            Package_Quantity: 1,
            Item_Quantity: quantity,
        };

    }



    let items = [newItem]


    if (misData.data.DeliveryType.Name == 'ToCentre') {

        const contactName = () => {
            if (misData.data.CentreDeliveryAddress.ContactName !== undefined && misData.data.CentreDeliveryAddress.ContactName !== null) { return misData.data.CentreDeliveryAddress.ContactName } else { return '' }
        }

        const newPackage = {
            shipFrom: shipFrom,
            orderId: misData.data.OrderId,
            shipTo: misData.data.CentreDeliveryAddress.CompanyName + ' ' + contactName(),
            phone: misData.data.CentreDeliveryAddress.ContactPhone,
            address1: misData.data.CentreDeliveryAddress.Address1,
            address2: misData.data.CentreDeliveryAddress.Address2,
            city: misData.data.CentreDeliveryAddress.City,
            postcode: misData.data.CentreDeliveryAddress.Postcode,
            state: misData.data.CentreDeliveryAddress.State.Abbreviation,
            authority: 'false',
            items: items,
            instructions: misData.deliveryData.Items[0].Options.SpecialInstructions,
            error: 'items not found'
        };

        packages.push(newPackage)

    }

    if (misData.data.DeliveryType.Name == 'ToCustomer') {

        const companyName = () => {
            if (misData.data.OrderNonCentreDeliveries[0].Address.CompanyName !== undefined && misData.data.OrderNonCentreDeliveries[0].Address.CompanyName !== null) { return misData.data.OrderNonCentreDeliveries[0].Address.CompanyName } else { return '' }
        }

        const contactName = () => {
            if (misData.data.OrderNonCentreDeliveries[0].Address.ContactName !== undefined && misData.data.OrderNonCentreDeliveries[0].Address.ContactName !== null) { return misData.data.OrderNonCentreDeliveries[0].Address.ContactName } else { return '' }
        }

        const ContactPhone = () => {
            if (misData.data.OrderNonCentreDeliveries[0].Address.ContactPhone !== undefined && misData.data.OrderNonCentreDeliveries[0].Address.ContactPhone !== null) { return misData.data.OrderNonCentreDeliveries[0].Address.ContactPhone } else { return '' }
        }

        const newPackage = {
            shipFrom: shipFrom,
            orderId: misData.data.OrderId,
            shipTo: companyName() + ' ' + contactName(),
            phone: ContactPhone(),
            email: '',
            address1: misData.data.OrderNonCentreDeliveries[0].Address.Address1,
            address2: misData.data.OrderNonCentreDeliveries[0].Address.Address2,
            city: misData.data.OrderNonCentreDeliveries[0].Address.City,
            postcode: misData.data.OrderNonCentreDeliveries[0].Address.Postcode,
            state: misData.data.OrderNonCentreDeliveries[0].Address.State.Abbreviation,
            authority: 'false',
            items: items,
            instructions: misData.deliveryData.Items[0].Options.SpecialInstructions,
            error: 'items not found'
        };

        packages.push(newPackage)

    }

    return { packages };

}