import { useEffect, useState } from "react";

import { Box, IconButton, Menu, MenuItem, Select } from "@mui/material";
import { green, red, grey } from '@mui/material/colors';
import PrintIcon from '@mui/icons-material/Print';

import ScaleIcon from '@mui/icons-material/Scale';

const ScaleConnect = (props: any) => {

    const connectScale = () => {
        navigator.hid.requestDevice({ filters: [{ vendorId: 0x0922 }] })
    }

    return (
        <Box sx={{ background: 'background.paper', paddingTop: '10px' }}>
            <IconButton onClick={() => connectScale()} id="scale-button" style={{ color: 'inherit' }}>
                <ScaleIcon />
            </IconButton>
        </Box>
    )
}

export default ScaleConnect;