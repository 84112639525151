import { SvgIcon, SvgIconProps } from "@mui/material";

export const DotIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            width="32"
            height="25"
            viewBox="0 0 32 25"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <ellipse cx="15" cy="5" rx="5" ry="5"/>
        </SvgIcon>
    );
};
