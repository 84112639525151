import {
    IconButton,
    FormControl,
    TextField,
    Button,
    Grid,
    ListItem,
    ListItemText,
    InputLabel,
    Select,
    MenuItem,
    Chip
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ScaleIcon from '@mui/icons-material/Scale';
import ErrorIcon from '@mui/icons-material/Error';
import SearchIcon from '@mui/icons-material/Search';

import React, { useState, useEffect, useContext } from 'react';
import { database } from 'utility/appwriteClient';
import { TentantContext } from "contexts/tentant";

import { Item, ItemType, PackageData } from 'interfaces';

import { sortData as sortDataMIS } from 'components/pluginsMIS/lodge-new-package/orderDataMIS'
import { sortData as sortDataSynergy} from 'components/pluginSynergy/lodge-new-package/orderDataSynergy'
import { set } from 'react-hook-form';

interface NewLodgementFormProps {
    setPackages: (packages: PackageData[]) => void;
    scannedBarcode: any;
    packageData: any;
    setLodgement: any;
    setOrderLookup: any;
    orderData: any;
    linkedOrders: string[];
}

const NewLodgementScreen: React.FC<NewLodgementFormProps> = ({ setPackages, packageData, setLodgement, setOrderLookup, orderData, linkedOrders, scannedBarcode }) => {

    const [tentant] = useContext(TentantContext);
    const [packages, setCurrentPackages] = useState<PackageData[]>([]);
    const [editingIndex, setEditingIndex] = useState<number>(-1);
    const [lockSearch, setLockSearch] = useState<boolean>(false);

    const [shipFrom, setShipFrom] = useState('');
    const [orderId, setOrderId] = useState('');
    const [shipTo, setShipTo] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [postcode, setPostcode] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [instructions, setInstructions] = useState('');
    const [leave, setLeave] = useState('');
    const [items, setItems] = useState<Item[]>([]);
    const [itemTypes, setItemTypes] = useState<ItemType[]>([]);
    const [extraOne, setExtraOne] = useState<string[]>([]);
    const [packageOrderData, setPackageOrderData] = useState<any>({});
    const [error, setError] = useState('');


    const [intState, setIntState] = useState('');
    const [intDesc, setIntDesc] = useState('');
    const [taxID, setTaxID] = useState('');
    const [incoterm, setIncoterm] = useState('');
    const [exportReason, setExportReason] = useState('');

    const [selectedItemTypes, setSelectedItemTypes] = useState<{ [itemId: string]: ItemType }>({});

    useEffect(() => {
        let sortedData: PackageData | any;

        if (packageData.length > 0) {
            setLockSearch(true);
            setOrderId(packageData[0].orderId);
        }

        if (orderData.data && packageData.length === 0) {

            if (orderData.sourceCode === 'MIS') {
                sortedData = sortDataMIS(orderData, shipFrom).packages[0]
            }

            if (orderData.sourceCode === 'Synergy') {
                sortedData = sortDataSynergy(orderData, shipFrom).packages[0]
            }

            if (sortedData != undefined) {

                setLockSearch(true);

                const packageToEdit = sortedData;
                setOrderId(packageToEdit.orderId);
                setPhone(packageToEdit.phone);
                setEmail(packageToEdit.email);
                setShipTo(packageToEdit.shipTo.replace(/[^A-Z0-9]/ig, " "));
                setAddress1(packageToEdit.address1);
                setAddress2(packageToEdit.address2);
                setCity(packageToEdit.city);
                setPostcode(packageToEdit.postcode.trim());
                setState(packageToEdit.state);
                setItems(packageToEdit.items);
                setLeave(packageToEdit.authority);
                setInstructions(packageToEdit.instructions);

                setPackageOrderData(orderData.data)

                if (packageToEdit.state == 'INT') {
                    setCountry(packageToEdit.country);
                    setIntState(packageToEdit.intState);
                    setIntDesc('Printed Artwork');
                    setTaxID('VAT/GST');
                    setIncoterm('DAP');
                    setExportReason('Personal Belongings or Personal Use');
                }
            }
        }

    }, [orderData]);

    const fetchPackages = async () => {

        try {
            const response = await database.listDocuments(tentant.toLowerCase(), tentant.toLowerCase() + '_preset');
            setItemTypes(response.documents as unknown as ItemType[]);

            const shipFromDefault = await database.listDocuments(tentant.toLowerCase(), tentant.toLowerCase() + '_default');
            setShipFrom(shipFromDefault.documents[0].Name + ' ' + shipFromDefault.documents[0].Address_Postcode + ' ' + shipFromDefault.documents[0].Address_State);
        } catch (error) {
            console.error('Error fetching packages:', error);
        }
    };

    useEffect(() => {
        fetchPackages();
        setCurrentPackages(packageData);
    }, []);

    useEffect(() => {
        setExtraOne(linkedOrders)
    }, [linkedOrders]);

    const handleAddPackage = () => {
        // Validate the required fields
        if (!shipFrom || !orderId || !shipTo || !address1 || !city || !postcode || !state || items.length === 0 || !leave) {
            alert('Please fill in all fields.');
            return;
        }

        const regex = /^[0-9\b ]+$/;
        if (phone != undefined && phone.length !== 0 && regex.test(phone) === false)   {
            alert('Please check phone number, no letters allowed.');
            return;
        }
        

        if (phone != undefined && phone.length > 19) {
            alert('Please check phone number, only 20 characters allowed.');
        }

        // Validate the items
        const isItemsValid = items.every((item) => item.Package_Length > 0 && item.Package_Width > 0 && item.Package_Height > 0 && item.Package_Weight > 0 && item.Package_Quantity > 0);
        if (!isItemsValid) {
            alert('Please enter valid values for item dimensions and quantity.');
            return;
        }

        // Create a new package
        const newPackage: PackageData = {
            shipFrom,
            orderId,
            phone,
            email,
            shipTo,
            address1,
            address2,
            city,
            postcode,
            state,
            items,
            instructions,
            leave,
            extraOne,
            packageOrderData,
            error,
            country,
            intState,
            intDesc,
            taxID,
            incoterm,
            exportReason
        };

        // Add the new package to the packages list
        setCurrentPackages((prevPackages) => [...prevPackages, newPackage]);

        // Reset the form fields for the next package
        setOrderId('');
        setShipTo('');
        setPhone('');
        setEmail('');
        setAddress1('');
        setAddress2('');
        setCity('');
        setPostcode('');
        setState('');
        setInstructions('');
        setLeave('');
        setItems([]);
        setExtraOne([]);

        setCountry('');
        setIntState('');
        setIntDesc('');
        setTaxID('');
        setIncoterm('');
        setExportReason('');

        setEditingIndex(-1);

        setOrderLookup('reset')
        setLockSearch(false)
    };

    const handleEditPackage = (index: number) => {
        setLockSearch(true)

        const packageToEdit = packages[index];

        scannedBarcode(packageToEdit.orderId)


        setOrderId(packageToEdit.orderId);
        setShipTo(packageToEdit.shipTo.replace(/[^A-Z0-9]/ig, " "));
        setPhone(packageToEdit.phone);
        setEmail(packageToEdit.email);
        setAddress1(packageToEdit.address1);
        setAddress2(packageToEdit.address2);
        setCity(packageToEdit.city);
        setPostcode(packageToEdit.postcode.trim());
        setState(packageToEdit.state);
        setItems(packageToEdit.items);
        setInstructions(packageToEdit.instructions);
        setLeave(packageToEdit.leave);
        setExtraOne(packageToEdit.extraOne);
        setPackageOrderData(packageToEdit.packageOrderData);

        setCountry(packageToEdit.country);
        setIntState(packageToEdit.intState);
        setIntDesc(packageToEdit.intDesc);
        setTaxID(packageToEdit.taxID);
        setIncoterm(packageToEdit.incoterm);
        setExportReason(packageToEdit.exportReason);

        setEditingIndex(index);
    };

    const handleUpdatePackage = () => {
        if (editingIndex === -1) return;

        // Validate the required fields
        if (!shipFrom || !orderId || !shipTo || !address1 || !city || !postcode || !state || items.length === 0 || !leave) {
            alert('Please fill in all fields.');
            return;
        }

        const regex = /^[0-9\b ]+$/;
        if (phone != undefined && phone.length !== 0 && regex.test(phone) === false)   {
            alert('Please check phone number, no letters allowed.');
            return;
        }
        

        if (phone != undefined && phone.length > 19) {
            alert('Please check phone number, only 20 characters allowed.');
        }

        // Validate the items
        const isItemsValid = items.every((item) => item.Package_Length > 0 && item.Package_Width > 0 && item.Package_Height > 0 && item.Package_Weight > 0 && item.Package_Quantity > 0);
        if (!isItemsValid) {
            alert('Please enter valid values for item dimensions and quantity.');
            return;
        }

        const updatedPackage: PackageData = {
            shipFrom,
            orderId,
            phone,
            email,
            shipTo,
            address1,
            address2,
            city,
            postcode,
            state,
            items,
            instructions,
            leave,
            extraOne,
            packageOrderData,
            error: '',
            country,
            intState,
            intDesc,
            taxID,
            incoterm,
            exportReason
        };

        setCurrentPackages((prevPackages) => {
            const updatedPackages = [...prevPackages];
            updatedPackages[editingIndex] = updatedPackage;
            return updatedPackages;
        });

        // Reset the form fields and editing index
        setOrderId('');
        setShipTo('');
        setPhone('');
        setEmail('');
        setAddress1('');
        setAddress2('');
        setCity('');
        setPostcode('');
        setState('');
        setInstructions('');
        setLeave('');
        setItems([]);
        setExtraOne([]);
        setPackageOrderData({});
        setEditingIndex(-1);

        setCountry('');
        setIntState('');
        setIntDesc('');
        setTaxID('');
        setIncoterm('');
        setExportReason('');

        setOrderLookup('reset')
        setLockSearch(false)
    };

    const handleDeletePackage = (index: number) => {
        setCurrentPackages((prevPackages) => {
            const updatedPackages = [...prevPackages];
            updatedPackages.splice(index, 1);
            return updatedPackages;
        });
        if (index === editingIndex) {
            setOrderId('');
            setShipTo('');
            setPhone('');
            setEmail('');
            setAddress1('');
            setAddress2('');
            setCity('');
            setPostcode('');
            setState('');
            setInstructions('');
            setLeave('');
            setItems([]);
            setExtraOne([]);
            setPackageOrderData({});
            setEditingIndex(-1);

            setCountry('');
            setIntState('');
            setIntDesc('');
            setTaxID('');
            setIncoterm('');
            setExportReason('');

            setOrderLookup('reset')
            setLockSearch(false)
        }
    };

    const handleAddItem = () => {
        const newItem: Item = {
            id: String(Date.now()),
            type: '',
            Package_Name: '',
            Package_Type: '',
            Package_Length: 0,
            Package_Width: 0,
            Package_Height: 0,
            Package_Weight: 0,
            Package_Quantity: 0,
            Item_Quantity: 0,
        };

        setItems((prevItems) => [...prevItems, newItem]);
    };

    const handleRemoveItem = (itemId: string) => {
        setItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
    };

    const handleItemFieldChange = (itemId: string, field: keyof Item, value: string) => {
        if (field === 'type') {
            const selectedItemType = itemTypes.find((itemType) => itemType.Package_Name === value);
            setSelectedItemTypes((prevSelectedItemTypes) => {
                const updatedSelectedItemTypes = { ...prevSelectedItemTypes };
                updatedSelectedItemTypes[itemId] = selectedItemType as ItemType;
                return updatedSelectedItemTypes;
            });
            setItems((prevItems) =>
                prevItems.map((item) => {
                    if (item.id === itemId) {
                        return {
                            ...item,
                            ...selectedItemType,
                        };
                    }
                    return item;
                })
            );
        } else {
            setItems((prevItems) =>
                prevItems.map((item) => {
                    if (item.id === itemId) {
                        return {
                            ...item,
                            [field]: Number(value),
                        };
                    }
                    return item;
                })
            );
        }
    };

    const setweightfromscale = (event: { data: any; device: any; reportId: any }, i: any) => {
        const { data, device, reportId } = event
        let buffArray = new Uint8Array(data.buffer)
        var weighttotal = buffArray[3]
        //check scale settings
        if (buffArray[1] === 3) {
            if (buffArray[0] != 0) {
                handleItemFieldChange(i, 'Package_Weight', (buffArray[3] / 10).toString())
            }
            if (buffArray[3] === 0) {
                handleItemFieldChange(i, 'Package_Weight', '0.5')
            }
        }
        else if (buffArray[1] === 12) {
            if (buffArray[0] != 0) {
                handleItemFieldChange(i, 'Package_Weight', (Math.round(weighttotal / 2.205) / 10).toString())
            }
            if (buffArray[3] === 0) {
                handleItemFieldChange(i, 'Package_Weight', '0.5')
            }
        }
        i = null
    }

    const setWeight = async (i: any) => {
        console.log('usb weight')
        const scaleweightsettings = function (listen: any) { setweightfromscale(listen, i) }
        await navigator.hid.getDevices()
            .then(devices => {
                devices.forEach(device => {
                    device.open()
                        .then(() => device.addEventListener("inputreport", scaleweightsettings))
                        .then(() => new Promise(resolve => setTimeout(resolve, 200)))
                        .then(() => device.removeEventListener("inputreport", scaleweightsettings))
                        .then(() => device.close())
                })
            })
    }

    const finishForm = () => {
        setPackages(packages);
        setLodgement(true);
    };

    return (

        <Grid container spacing={2} sx={{ background: 'primary' }} hidden={true}>

            <Grid item xs={12} sm={3} sx={{ paddingRight: '20px', paddingTop: '1px' }}>
                {packages.map((packageData, index) => (

                    <ListItem key={index} sx={{ paddingRight: '100px' }}

                        divider={true}

                        secondaryAction={
                            <div>
                                {packageData.error &&
                                    <IconButton edge="end" aria-label="error">
                                        <ErrorIcon color="error" />
                                    </IconButton>
                                }
                                <IconButton edge="end" aria-label="edit" onClick={() => handleEditPackage(index)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeletePackage(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        }
                    >

                        <ListItemText
                            primary={'Package ' + (index + 1)}
                            primaryTypographyProps={{ variant: 'subtitle1' }}
                            secondary={packageData.shipTo}
                            secondaryTypographyProps={{ variant: 'body2' }}
                        />

                    </ListItem>

                ))}

                <div style={{ paddingTop: "5px" }}>

                    {editingIndex === -1 && (
                        <Button variant="contained" color="primary" sx={{ margin: "5px" }} onClick={handleAddPackage}>
                            Save New
                        </Button>
                    )}
                    {editingIndex !== -1 && (
                        <Button variant="contained" color="primary" sx={{ margin: "5px" }} onClick={handleAddPackage}>
                            Duplicate
                        </Button>
                    )}
                    {editingIndex !== -1 && (
                        <Button variant="contained" color="primary" sx={{ margin: "5px" }} onClick={handleUpdatePackage}>
                            Save Changes
                        </Button>
                    )}
                    {editingIndex === -1 && packages.length > 0 && packages.length < 2 && (
                        <Button variant="contained" color="primary" sx={{ margin: "5px" }} onClick={finishForm}
                        disabled={address1 !== ''}>
                            Submit {packages.length} Package
                        </Button>
                    )}
                    {editingIndex === -1 && packages.length > 1 && (
                        <Button variant="contained" color="primary" sx={{ margin: "5px" }} onClick={finishForm}>
                            Submit {packages.length} Packages
                        </Button>
                    )}

                </div>

            </Grid>

            <Grid item xs={12} sm={9}>

                <Grid container spacing={2} >

                    <Grid item xs={12} sm={8}>
                        <TextField
                            label="Ship From"
                            value={shipFrom}
                            onChange={(e) => setShipFrom(e.target.value)}
                            fullWidth
                            disabled={true}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Order ID"
                            value={orderId}
                            onChange={(e) => setOrderId(e.target.value)}
                            fullWidth
                            disabled={lockSearch}
                            required={true}
                            InputProps={{
                                endAdornment: (
                                    <IconButton edge="end" aria-label="edit" disabled={lockSearch} onClick={() => setOrderLookup(orderId)}>
                                        <SearchIcon />
                                    </IconButton>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField
                            label="Deliver To"
                            value={shipTo}
                            onChange={(e) => setShipTo(e.target.value.replace(/[^A-Z0-9]/ig, " "))}
                            fullWidth
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="Phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Address 1"
                            value={address1}
                            onChange={(e) => setAddress1(e.target.value)}
                            fullWidth
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Address 2"
                            value={address2}
                            onChange={(e) => setAddress2(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField
                            label="City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            fullWidth
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="Postcode"
                            value={postcode}
                            onChange={(e) => setPostcode(e.target.value.trim())}
                            fullWidth
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel required={true} id="state-label">AU State</InputLabel>
                            <Select
                                label="State"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                fullWidth
                                required={true}
                            >
                                <MenuItem value={'TAS'}>TAS</MenuItem>
                                <MenuItem value={'VIC'}>VIC</MenuItem>
                                <MenuItem value={'NSW'}>NSW</MenuItem>
                                <MenuItem value={'ACT'}>ACT</MenuItem>
                                <MenuItem value={'QLD'}>QLD</MenuItem>
                                <MenuItem value={'NT'}>NT</MenuItem>
                                <MenuItem value={'SA'}>SA</MenuItem>
                                <MenuItem value={'WA'}>WA</MenuItem>
                                <MenuItem value={'INT'}>International</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            label="Instructions"
                            value={instructions}
                            onChange={(e) => setInstructions(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel required={true} id="authority-label">Authority to Leave</InputLabel>
                            <Select
                                label="Authority to Leave"
                                value={leave}
                                onChange={(e) => setLeave(e.target.value)}
                                fullWidth
                                required={true}
                            >
                                <MenuItem value={'true'}>Leave Parcel</MenuItem>
                                <MenuItem value={'false'}>Do Not Leave</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {state === 'INT' && (

                    <Grid container spacing={2} sx={{ paddingLeft: '16px', paddingTop: '10px' }}>

                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="International State"
                            value={intState}
                            onChange={(e) => setIntState(e.target.value)}
                            fullWidth
                            required={true}
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={5}>
                        <TextField
                            label="Description"
                            value={intDesc}
                            onChange={(e) => setIntDesc(e.target.value)}
                            fullWidth
                            required={true}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel required={true} id="tax-id-label">Tax ID</InputLabel>
                            <Select
                                label="Tax ID"
                                value={taxID}
                                onChange={(e) => setTaxID(e.target.value)}
                                fullWidth
                                required={true}
                            >
                                <MenuItem value={'VAT/GST'}>VAT/GST</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={8}>
                        <FormControl fullWidth>
                            <InputLabel required={true} id="export-reason-label">Export Reason</InputLabel>
                            <Select
                                label="Export Reason"
                                value={exportReason}
                                onChange={(e) => setExportReason(e.target.value)}
                                fullWidth
                                required={true}
                            >
                                <MenuItem value={'Personal Belongings or Personal Use'}>Personal Belongings or Personal Use</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel required={true} id="incoterm-label">Incoterm</InputLabel>
                            <Select
                                label="Incoterm"
                                value={incoterm}
                                onChange={(e) => setIncoterm(e.target.value)}
                                fullWidth
                                required={true}
                            >
                                <MenuItem value={'DAP'}>DAP</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    </Grid>

                    )}

                    <Grid item sm={12}>
                        <h3>Package Items</h3>
                    </Grid>

                    {items.map((item, index) => (
                        <Grid container spacing={1} key={index} sx={{ paddingLeft: '16px', paddingTop: '10px' }}>
                            <Grid item sm={2}>
                                <FormControl variant="outlined" sx={{ width: '100%' }}>
                                    <InputLabel>Package</InputLabel>
                                    <Select
                                        value={item.Package_Name}
                                        onChange={(e) => handleItemFieldChange(item.id, 'type', e.target.value as string)}
                                        label="Item Type"
                                    >
                                        {itemTypes.map((itemType) => (
                                            <MenuItem key={itemType.Package_Name} value={itemType.Package_Name}>
                                                {itemType.Package_Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={1.5}>
                                <TextField
                                    label="Type"
                                    type="string"
                                    value={item.Package_Type}
                                    onChange={(e) => handleItemFieldChange(item.id, 'Package_Type', e.target.value)}
                                />
                            </Grid>
                            <Grid item sm={1}>
                                <TextField
                                    label="Length"
                                    type="number"
                                    value={item.Package_Length}
                                    onChange={(e) => handleItemFieldChange(item.id, 'Package_Length', e.target.value)}
                                />
                            </Grid>
                            <Grid item sm={1}>
                                <TextField
                                    label="Width"
                                    type="number"
                                    value={item.Package_Width}
                                    onChange={(e) => handleItemFieldChange(item.id, 'Package_Width', e.target.value)}
                                />
                            </Grid>
                            <Grid item sm={1}>
                                <TextField
                                    label="Height"
                                    type="number"
                                    value={item.Package_Height}
                                    onChange={(e) => handleItemFieldChange(item.id, 'Package_Height', e.target.value)}
                                />
                            </Grid>
                            <Grid item sm={1.2}>
                                <TextField
                                    label="KG"
                                    type="number"
                                    value={item.Package_Weight}
                                    onChange={(e) => handleItemFieldChange(item.id, 'Package_Weight', e.target.value)}
                                />
                            </Grid>
                            <Grid item sm={1}>
                                <IconButton sx={{ paddingTop: '17px' }} edge="end" aria-label="weight" onClick={(e) => setWeight(item.id)}>
                                    <ScaleIcon />
                                </IconButton>
                            </Grid>
                            <Grid item sm={1}>
                                <TextField
                                    label="Boxes"
                                    type="number"
                                    value={item.Package_Quantity}
                                    onChange={(e) => handleItemFieldChange(item.id, 'Package_Quantity', e.target.value)}
                                />
                            </Grid>
                            <Grid item sm={1}>
                                <IconButton sx={{ paddingTop: '17px' }} edge="end" aria-label="delete" onClick={() => handleRemoveItem(item.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                            {orderData.sourceCode === 'MIS' &&
                                <Grid item sm={1.2}>
                                    <TextField
                                        label="QTY"
                                        type="number"
                                        value={item.Item_Quantity}
                                        onChange={(e) => handleItemFieldChange(item.id, 'Item_Quantity', e.target.value)}
                                    />
                                </Grid>}
                        </Grid>

                    ))}

                    <Grid item sm={12}>
                        <Button variant="outlined" color="primary" onClick={handleAddItem} sx={{ marginTop: '20px' }}>
                            Add New Box / Item to Order
                        </Button>
                    </Grid>

                </Grid>


                {extraOne.length > 0 &&
                    <Grid item sm={12}>
                        <h3>Linked Orders</h3>
                        {extraOne.map((order, index) => (
                            <Chip key={index} label={order} sx={{ marginRight: '10px' }} />
                        ))}
                    </Grid>
                }

            </Grid>
        </Grid>
    )
}

export default NewLodgementScreen