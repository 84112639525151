import { hextorstr, KEYUTIL, KJUR, stob64 } from "jsrsasign"
import * as qz from "qz-tray"

import { functions } from "utility"

const QZKey = () => {

    // Set Certificate Promise
    qz.security.setCertificatePromise(function (resolve:any, reject:any) {
        fetch(
            "/digital-certificate.txt",
            { cache: "no-store", headers: { "Content-Type": "text/plain" } }
        ).then(function (data) {
            data.ok ? resolve(data.text()) : reject(data.text())
        })
    })

    
    qz.security.setSignaturePromise(function (toSign:any) {
        return function (resolve:any, reject:any) {
            try {

                functions.createExecution('qzTraySigning','{"message": "' + toSign + '"}').then(function (data: any) {
                    resolve(JSON.parse(data.response)['signature'])
                })

            } catch (err) {
                console.error(err)
                reject(err)
            }
        }
    })

    return (null)
}

export default QZKey