const printLabelLogic = (source:any, lodgeRequest:any) => {

    if (source === 'OpenFreight') {

        const qz = require("qz-tray")

        var token
        if (typeof window !== 'undefined') {
            token = localStorage.getItem('printer_selected')
        }
        var config = qz.configs.create(token)

        qz.print( config, [ atob(JSON.parse(lodgeRequest.response).label) ] ).catch(function (e: any) { console.error(e); })

    }

    if (source === 'Shippit') {

        window.open(JSON.parse(lodgeRequest.response).label, '_blank', 'noreferrer');

    }

    return ( null )

}

export default printLabelLogic