import { Box, Grid, CircularProgress, Backdrop, List, ListItem, ListItemText, Typography, ListSubheader, Hidden } from '@mui/material';

import React, { useState, useEffect, useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Moment from 'moment';

import { database, functions } from 'utility/appwriteClient';
import { TentantContext } from "contexts/tentant";

import { PluginList, PackageData } from 'interfaces';

import BarcodeScannerMIS from 'components/pluginsMIS/lodge-new-package/barcodeScannerMIS'
import BarcodeScannerLB from 'components/pluginSynergy/lodge-new-package/barcodeScannerSynergy'

import NewLodgementScreen from 'components/pages/lodge-new-package/newPackageScreen'
import PackageLodgementScreen from 'components/pages/lodge-new-package/SubmitPackageScreen'



export const NewLodgement = () => {

    const [tentant] = useContext(TentantContext);
    const [plugins, setPlugins] = useState<PluginList[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [lodgementSubmit, setLodgementSubmit] = useState<boolean>(false);
    const [showSubmitPackage, setShowSubmitPackage] = useState<boolean>(false);

    const [scannedOrderID, setScannedOrderID] = useState('');
    const [loadedScannedID, setLoadedScannedID] = useState('');
    const [linkedOrders, setLinkedOrders] = useState<string[]>([]);

    const [packages, setPackages] = useState<PackageData[]>([]);

    const [imageUrl, setImageUrl] = useState('');
    const [orderData, setOrderData] = useState<any>({});

    // Load plugins to use
    useEffect(() => {

        setLoading(true)

        database.listDocuments(tentant.toLowerCase(), tentant.toLowerCase() + '_plugin')
            .then((response) => {
                setPlugins(response.documents as unknown as PluginList[]);
            })
            .then(() => setLoading(false))
            .catch((error) => {
                console.error(error);
            });

    }, []);

    // Load scanned order ID
    useEffect(() => {

        if (scannedOrderID === 'reset') {
            setImageUrl('')
            setLoadedScannedID('')
            setScannedOrderID('')
            setLinkedOrders([])
        }

        if (loadedScannedID === '' && scannedOrderID !== '' && scannedOrderID !== 'reset') {
            setLoadedScannedID(scannedOrderID)

            setLoading(true)

            const fetchPluginData = async () => {

                await Promise.all(
                    plugins.filter((item) => item.Type === 'Image').map(async (pluginDetail: any) => {
                        if (pluginDetail.Name === 'MIS Image') {
                            const response = await functions.createExecution('misGetPreview', '{"orderId": "' + scannedOrderID.trim() + '"}')
                                .then((r) => { if (JSON.parse(r.response).message === "200") setImageUrl(JSON.parse(r.response).imageURL) })
                        }
                    }))

                await Promise.all(
                    plugins.filter((item) => item.Type === 'Data').map(async (pluginDetail: any) => {
                        if (pluginDetail.Name === 'MIS Data') {
                            const response = await functions.createExecution('misGetOrder', '{"orderId": "' + scannedOrderID.trim() + '"}')
                            console.log( JSON.parse(response.response) )

                            if (JSON.parse(response.response).orderData.StatusName != 'Finishing' || JSON.parse(response.response).accountData.LockState != 'Unlocked' || JSON.parse(response.response).accountCustomerData.AccountRestriction.Id != "0") {
                                alert('Status is not in Finishing or Locked, cannot complete order')
                                setImageUrl('')
                                setLoadedScannedID('')
                                setScannedOrderID('')
                            } else {
                                setOrderData({ "data": JSON.parse(response.response).orderData, "deliveryData": JSON.parse(response.response).deliveryData, "sourceCode": "MIS" })
                            }
                        }

                        if (pluginDetail.Name === 'Synergy Data') {
                            const response = await functions.createExecution('synergyGetOrder', '{"orderId": "' + scannedOrderID.trim() + '", "tentant": "' + tentant.toLowerCase() + '"}')
                            console.log( JSON.parse(response.response) )

                            setOrderData({ "data": JSON.parse(response.response).orderData, "deliveryData": JSON.parse(response.response).deliveryData, "sourceCode": "Synergy" })
                        }
                    }))

                setLoading(false)
            }

            fetchPluginData()

        } else if (loadedScannedID !== scannedOrderID && scannedOrderID !== 'reset') {

            setLinkedOrders(linkedOrders => linkedOrders.concat(scannedOrderID));

        }

    }, [scannedOrderID]);

    //Submit package to database when packages updated
    useEffect(() => {

        if (lodgementSubmit == false && packages.length == 0) {

            setOrderData({})
            setLoadedScannedID('')
            setLinkedOrders([])
            setScannedOrderID('')
            setImageUrl('')

        }

        if (lodgementSubmit == false) {

            setShowSubmitPackage(false)

        }

        if (packages.length > 0 && lodgementSubmit === true) {

            const lodgeHandler = async () => {
                //await handleSubmitPackage();
                setShowSubmitPackage(true)
            }

            lodgeHandler();
        }

    }, [lodgementSubmit]);




    return (
        <Box sx={{ p: 4, borderRadius: 4, backgroundColor: 'background.paper' }}>

            <h1>New Lodgement</h1>

            {plugins.filter((item) => item.Type === 'Barcode').map((pluginDetail: any) => {
                if (pluginDetail.Name === 'MIS Barcode') {
                    return (<BarcodeScannerMIS key={'barcodeMIS'} setOrderID={setScannedOrderID} />)
                }
                if (pluginDetail.Name === 'Synergy Barcode') {
                    return (<BarcodeScannerLB key={'barcodeSynergy'} setOrderID={setScannedOrderID} />)
                }
            })}

            {imageUrl !== '' && !showSubmitPackage &&
                <Grid container spacing={2} sx={{ paddingBottom: '50px' }}>
                    <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={imageUrl} alt="Order Preview" style={{ maxWidth: '100%', maxHeight: '250px' }} />
                    </Grid>
                    <Grid item xs={7} height='250px' overflow='none'>
                        <Scrollbars style={{ height: 250 }}>
                            {orderData.data?.OrderNotes &&
                            <List sx={{ bgcolor: 'background.paper', marginRight: '50px' }} subheader={<ListSubheader>{orderData.data?.OrderNotes?.length} Total Notes</ListSubheader>}>
                                {orderData.data?.OrderNotes
                                    .sort((a: any, b: any) => Moment(b.DateCreated).diff(Moment(a.DateCreated)))
                                    .map((noteItem: any, index: any) => {
                                        return (
                                            <ListItem alignItems="flex-start" divider={true} key={index}>
                                                <ListItemText
                                                    primary={noteItem.NoteText}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{ display: 'inline' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                {'Note By ' + noteItem.UserName}
                                                            </Typography>
                                                            <br />{noteItem.Type + ' Note ' + Moment(noteItem.DateCreated).fromNow()}
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        )
                                    })}
                            </List>
                            }
                        </Scrollbars>
                    </Grid>
                </Grid>
            }

            <Hidden xsUp={showSubmitPackage} implementation='js'>
                <NewLodgementScreen setPackages={setPackages} packageData={packages} setLodgement={setLodgementSubmit} setOrderLookup={setScannedOrderID} orderData={orderData} linkedOrders={linkedOrders} scannedBarcode={setLoadedScannedID} />
            </Hidden>

            <Hidden xsUp={!showSubmitPackage} implementation='js'>
                <PackageLodgementScreen loading={setLoading} setPackages={setPackages} packageData={packages} getEstimates={setLodgementSubmit} linkedOrders={linkedOrders} plugins={plugins} />
            </Hidden>

            <Backdrop open={loading} sx={{ zIndex: 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </Box >


    )
}