import { SVGProps } from "react";

export const ShipdIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            width="183"
            height="54"
            viewBox="0 0 183 54"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
                d="m4.96,29.92c.14-.21.43-.25.62-.09,1.19.99,5.2,4.03,9.59,4.03,2.09,0,4.36-.82,4.36-3.34C19.53,25.54.81,25.72.81,12.83.81,5.1,7.26,0,15.52,0c7.76,0,12.12,3.57,13.14,4.52.14.13.18.34.09.51l-3.92,7.63c-.12.24-.42.31-.63.15-1.15-.9-4.7-3.37-8.79-3.37-2.09,0-4.42.94-4.42,3.28,0,5.27,18.72,4.34,18.72,17.58,0,6.97-5.29,13.01-14.65,13.01-8.55,0-13.76-4.58-14.92-5.72-.15-.15-.17-.38-.05-.55l4.88-7.11Z"/><path d="m29.65.7h9.2c.24,0,.43.19.43.43v12.81c0,1.52-.12,2.58-.12,2.58h.12c1.69-2.99,5.17-4.8,9.13-4.8,6.05,0,10.76,2.81,10.76,11.31v19.14c0,.24-.19.43-.43.43h-9.2c-.24,0-.43-.19-.43-.43v-17.26c0-2.81-1.1-3.98-3.31-3.98-3.08,0-5.12,1.82-5.99,4.45-.41,1.17-.52,2.46-.52,3.87v12.93c0,.24-.19.43-.43.43h-9.2c-.24,0-.43-.19-.43-.43V1.13c0-.24.19-.43.43-.43Z"/><path d="m69.44,12.42h8.22c.24,0,.43.19.43.43v1.09c0,1-.12,1.93-.12,1.93h.12s2.73-4.16,9.07-4.16c8.14,0,13.49,6.44,13.49,15.82s-5.99,15.76-13.95,15.76c-4.88,0-7.62-2.87-7.62-2.87h-.12s.12,1.05.12,2.58v10.88c0,.24-.19.43-.43.43h-9.2c-.24,0-.43-.19-.43-.43V12.85c0-.24.19-.43.43-.43Zm15.21,22.5c3.14,0,5.87-2.58,5.87-7.21s-2.38-7.44-5.87-7.44c-3.02,0-5.87,2.23-5.87,7.44,0,3.63,1.92,7.21,5.87,7.21Z"/><path d="m114.05,11.72c6.05,0,7.85,2.4,7.85,2.4h.12s-.06-.59-.06-1.35V1.13c0-.24.19-.43.43-.43h9.21c.24,0,.43.19.43.43v41.03c0,.24-.19.43-.43.43h-8.57c-.24,0-.43-.19-.43-.43v-1.56c0-.94.06-1.7.06-1.7h-.12s-2.5,4.39-8.66,4.39c-8.14,0-13.49-6.44-13.49-15.82s5.7-15.76,13.66-15.76Zm2.33,23.2c3.02,0,5.87-2.23,5.87-7.44,0-3.63-1.92-7.21-5.87-7.21-3.14,0-5.87,2.58-5.87,7.21s2.38,7.44,5.87,7.44Z"
            />
            <ellipse cx="64.04" cy="5.71" rx="4.97" ry="5.01"/>
        </svg>
    );
};
