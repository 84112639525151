import { useContext, useEffect, useState } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { TentantContext } from "contexts/tentant";
import { teams } from "utility";


export const TentantSelect = () => {

    const [tentant, setTentant] = useContext(TentantContext);
    const [tentantSelectList, setTentantList] = useState([{$id:'', name:''}]);
    const [tentantSelected, setTentantSelected] = useState('');
    
    useEffect(() => {

        async function changeTentant() {
            
            var tentants = await teams.list().then((returnData) => {
                var tentantList = [{$id:'',name:""}]
                tentantList = returnData.teams
                var defaultTenant = tentantList[0].name

                setTentantList(tentantList)

                return(defaultTenant)
            }).then((returnData) => {

                setTentantSelected(tentant)

                if (tentant === '' && tentantSelectList.length > 0) {
                    setTentant(returnData)
                }
            })
        }

        changeTentant()

    }, [tentant]);

    const handleChange = (event: SelectChangeEvent) => {
        setTentant(event.target.value as string);
        setTentantSelected(event.target.value as string);
    };

    return (
        <Select
            value={tentantSelected}
            defaultValue={""}
            style={{ minWidth: 180, height: 40, marginLeft: 10, marginBottom: 20 }}
            onChange={handleChange}
            onSelect={() => false}
        >
            {tentantSelectList?.map(({ $id, name }) => (
                <MenuItem key={$id} value={name}>
                    {name}
                </MenuItem >
            ))}
        </Select>
    );
};