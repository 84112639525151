import { useEffect, useState } from "react";
import { teams } from "utility";
import { TeamList } from "interfaces";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';

import { StyledDataGrid } from "components/muiDataGrid";

export const ListTeams = () => {

    const [teamList, setTeamList] = useState<TeamList[]>([])

    const navigate = useNavigate();

    useEffect(() => {

        //get team list and assign to state
        teams.list()
            .then((response) => {
            // add a unique id to each row object
            const rows = response.teams.map((row, index) => ({
              id: row.$id,
              ...row,
            }));
            setTeamList(rows);
          })
          .catch((error) => {
            console.error(error);
          });

      }, []);

      const columns: GridColDef[] = [
        {
          field: 'name',
          headerName: 'Team Name',
          flex: 1,
        },
        {
            field: 'total',
            headerName: 'Total Users',
            flex: 1,
        },
      ];

      return (
        <div style={{ height: 400, width: '100%' }}>
          <StyledDataGrid
            rows={teamList}
            columns={columns}
            pageSizeOptions={[5, 10, 20]}
            disableRowSelectionOnClick
            onRowClick={({ id }) => {
              navigate(`show/${id}`);
            }}
          />
        </div>
      );

}