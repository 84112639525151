import React, { useState } from "react";
import { ThemedSiderV2 } from "@refinedev/mui";

import { TentantSelect } from "components/select/tentantSelect";
import { Title } from "components";

export const Slider: React.FC = () => {

    return (
        <ThemedSiderV2
            Title={({ collapsed }) => (
                <Title collapsed={collapsed} />
            )}
            render={({ items, logout, collapsed }) => {
                return (
                    <>
                        {collapsed ? '' : <TentantSelect/>}
                        {items}
                        {logout}
                    </>
                    )
            }}
        />
    )

};