import React, { useContext, useEffect, useState } from "react";
import Moment from 'moment';

import { IResourceComponentsProps } from "@refinedev/core";
import { List, ExportButton } from "@refinedev/mui";
import { Query } from "@refinedev/appwrite";

import { Grid, Box, Typography, Stack, CardHeader, IconButton, Backdrop, CircularProgress, TextField } from "@mui/material";

import { DataGrid, GridColDef, GridRowParams, getGridStringOperators } from "@mui/x-data-grid";

import { database, functions } from 'utility/appwriteClient';
import { TentantContext } from "contexts/tentant";

import { misLabelLogic, multipleLabelLogic } from "components/pluginsMIS/labels/printLabelsMIS"

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PrintIcon from '@mui/icons-material/Print';

import { PluginList, packageDetails, RowData, ItemData, options } from 'interfaces';


export const LodgedPackages: React.FC<IResourceComponentsProps> = () => {
    const [labelToPrint, setLabelToPrint] = useState<number>(0);

    const [packages, setPackages] = useState<packageDetails[]>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(0);
    const [sortData, setSortData] = useState<any>([]);
    const [filterData, setFilterData] = useState<any>({});
    const [searchText, setSearchText] = useState<string>('');

    const [selectedItem, setSelectedItem] = useState<RowData | null>(null);
    const [plugins, setPlugins] = useState<PluginList[]>([]);
    const [loading, setLoading] = useState<boolean>(false);


    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
      });


    //Load Plugins

    const [tentant] = useContext(TentantContext);

    useEffect(() => {

        database.listDocuments(tentant.toLowerCase(), tentant.toLowerCase() + '_plugin')
            .then((response) => {
                setPlugins(response.documents as unknown as PluginList[]);
            })
            .catch((error) => {
                console.error(error);
            });

    }, []);

    //Get Packages Data for Tentant

    useEffect(() => {
        fetchPackages();
    }, [pageCount, sortData, filterData, searchText]);

    const fetchPackages = async () => {

        const sortQuery = () => {
            if (sortData.length > 0) {
                if (sortData[0].sort === 'asc') {
                    return (Query.orderAsc('Order_Reference'))
                } else {
                    return (Query.orderDesc('Order_Reference'))
                }
            } else {
                return Query.orderDesc('$createdAt')
            }
        }

        const filterQuery = () => {
            if (filterData.items?.length > 0 && filterData.items[0].value !== undefined) {
                return (Query.search("Order_Reference", filterData.items[0].value))
            } else {
                return ('isNotNull("Order_Reference")')
            }
        }

        const orderQuery = () => {
            if (searchText !== '') {
                return (Query.search("Order_Reference", searchText))
            } else {
                return ('isNotNull("Order_Reference")')
            }
        }

        try {
            const response = await database.listDocuments(tentant.toLowerCase(), tentant.toLowerCase() + '_package',
                [
                    Query.limit(10),
                    Query.offset(pageCount),
                    sortQuery(),
                    filterQuery(),
                    orderQuery()
                ]);
            setPackages(response.documents as unknown as packageDetails[]);
            setRowCount(response.total);
        } catch (error) {
            console.error('Error fetching packages:', error);
        }
    };

    //Set Datagrid Rows and Columns

    const rows = packages.map((document) => ({
        id: document.$id,
        collection: document.$collectionId,
        order_reference: document.Order_Reference,
        customer_name: document.Customer_Name,
        address_city: document.Address_City,
        current_status: document.Status,
        created_at: Moment(new Date(document.$createdAt)).format('LLLL'),
    }));

    const filterOperators = getGridStringOperators().filter(({ value }) =>
        ['contains'].includes(value),
    );

    const columns: GridColDef[] = [
        { field: 'order_reference', headerName: 'Order Reference', flex: 1, filterOperators },
        { field: 'customer_name', headerName: 'Ship To', flex: 1, filterable: false, sortable: false },
        { field: 'current_status', headerName: 'Status', flex: 1, filterable: false, sortable: false },
        { field: 'created_at', headerName: 'Created', flex: 1, filterable: false, sortable: false },
    ];

    //Handle Clicks within the Rows
    const handleRowClick = (params: GridRowParams) => {
        setSelectedItem(params.row);
        packages.filter((p) => p.$id === params.row.id).map((item) => {
            handleGeocode(item.Address_Line_One + ' ' + item.Address_Line_Two + ' ' + item.Address_City + ' ' + item.Address_State + ' ' + item.Address_Postcode + ' Australia');
        })
        fetchItems(params.row.id);
        fetchOptions(params.row.id);
    };


    const goBack = () => {
        setSelectedItem(null);
    }

    //When opened handle map data

    const [latLng, setLatLng] = useState({ lat: null, lng: null });
    const [items, setItems] = useState<ItemData[]>([]);
    const [options, setOptions] = useState<options[]>([]);

    const handleGeocode = async (addressFind: string) => {
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${(addressFind.replace(/[-\s]+/g, '+'))}`);

            if (response.ok) {
                const data = await response.json();
                if (data.length > 0) {
                    const { lat, lon } = data[0];
                    setLatLng({ lat, lng: lon });
                } else {
                    setLatLng({ lat: null, lng: null });
                }
            } else {
                setLatLng({ lat: null, lng: null });
            }
        } catch (error) {
            console.error('Error geocoding address:', error);
        }
    };

    //When opened handle items data

    const fetchItems = async (linkId: string) => {

        try {
            const response = await database.listDocuments(tentant.toLowerCase(), tentant.toLowerCase() + '_items', [Query.equal("Link", [linkId])]);
            setItems(response.documents as unknown as ItemData[]);
        } catch (error) {
            console.error('Error fetching packages:', error);
        }
    };

    const fetchOptions = async (linkId: string) => {

        try {
            const response = await database.listDocuments(tentant.toLowerCase(), tentant.toLowerCase() + '_options', [Query.equal("Link", [linkId])]);
            setOptions(response.documents as unknown as []);
        } catch (error) {
            console.error('Error fetching packages:', error);
        }
    };

    //When printing set proper label stream

    const printLabel = (e: any) => {
        plugins.filter((item) => item.Type === 'Image').map(async (pluginDetail: any) => {

            setLoading(true)

            if (pluginDetail.Name === 'MIS Image') {
                const misData = await functions.createExecution('misGetOrder', '{"orderId": "' + e.Order_Reference + '"}')
                await misLabelLogic(e, items, JSON.parse(misData.response).orderData)
            }

            if (e.Source === 'OpenFreight') {
                const zplLabel = await functions.createExecution('getOpenFreightLabel', '{"consignment": "' + e.Shipment_Id + '","tentant": "' + tentant + '"}')

                if (JSON.parse(zplLabel.response).label !== undefined) {

                    const qz = require("qz-tray")

                    var token
                    if (typeof window !== 'undefined') {
                        token = localStorage.getItem('printer_selected')
                    }
                    var config = qz.configs.create(token)

                    qz.print(config, [atob(JSON.parse(zplLabel.response).label)]).catch(function (e: any) { console.error(e); })

                }
            }

            setLoading(false)
        })

        if (e.Source === 'Shippit') {
            setLoading(true)
            // window.open(e.Shipment_Label, '_blank', 'noreferrer');
            console.log('working')
            setLoading(false)
        }
    }

    const printLabelMultiple = (e: any) => {
        plugins.filter((item) => item.Type === 'Image').map(async (pluginDetail: any) => {

            setLoading(true)

            if (pluginDetail.Name === 'MIS Image') {
                const misData = await functions.createExecution('misGetOrder', '{"orderId": "' + e.Order_Reference + '"}')
                await multipleLabelLogic(e, items, JSON.parse(misData.response).orderData, labelToPrint)
            }

            setLoading(false)
        })
    }

    const pageChangeHandler = (e: any) => {
        setPaginationModel(e)
        setPageCount(e.page * 10)
    }




    return (
        <Box
            sx={{
                p: 4,
                borderRadius: 4,
                backgroundColor: 'background.paper',
            }}
        >
            <Grid container spacing={2} >
                <Grid item xs={12} lg={12}>

                    <Grid item xs={12} lg={12} hidden={!!selectedItem}>
                        <List
                            wrapperProps={{ sx: { paddingX: { xs: 2, md: 0 }, boxShadow: '0', backgroundColor: 'background.paper', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))' } }}
                            headerProps={{
                                action: (
                                    <div>
                                    <TextField label="OrderID" onChange={(e) => setSearchText(e.target.value)}></TextField>
                                     {/* <ExportButton sx={{marginTop: 1}}
                                    onClick={triggerExport}
                                    loading={isLoading}
                                    /> */}
                                    </div>
                                ),
                            }}
                        >
                            <DataGrid
                                autoHeight
                                rows={rows}
                                columns={columns}
                                pageSizeOptions={[10]}
                                paginationModel={paginationModel}
                                rowCount={rowCount}
                                pagination
                                paginationMode="server"
                                onPaginationModelChange={(e) => pageChangeHandler(e)}
                                onRowClick={handleRowClick}
                                onSortModelChange={(props) => { setSortData(props) }}
                                onFilterModelChange={(props) => { setFilterData(props) }}
                                components={{
                                    Toolbar: () => (
                                        <Box display="flex" alignItems="center">
                                        </Box>
                                    ),
                                }}
                            />
                        </List>
                    </Grid>

                    {selectedItem && (
                        <Grid>

                            {packages.filter((p) => p.$id === selectedItem.id).map((item) => (
                                <div key={1}>
                                    <CardHeader
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 2,
                                        }}
                                        title={
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <Typography variant="h6">
                                                    {'Order ' + item.Order_Reference}
                                                </Typography>
                                            </Stack>
                                        }
                                        subheader={'Shipped To ' + item.Customer_Name}
                                        avatar={
                                            <IconButton onClick={goBack}>
                                                <ArrowBackIcon />
                                            </IconButton>
                                        }
                                        action={
                                            <div>
                                                <IconButton onClick={() => printLabel(item)} sx={{ marginTop: 1, marginRight: 8 }}>
                                                    <PrintIcon />
                                                </IconButton>

                                                {/* <TextField
                                                    sx={{ width: 140 }}
                                                    label="Custom MIS QTY"
                                                    value={labelToPrint}
                                                    onChange={(e) => setLabelToPrint(parseInt(e.target.value))}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <IconButton edge="end" aria-label="edit" onClick={() => printLabelMultiple(item)}>
                                                                <PrintIcon /> 
                                                            </IconButton>
                                                        )
                                                    }}
                                                /> */}
                                            </div>
                                        }
                                    />
                                    <Grid container spacing={2} sx={{marginTop: 3, marginLeft: 1}}>
                                        <iframe
                                            width="50%"
                                            height="500px"
                                            src={`https://www.openstreetmap.org/export/embed.html?bbox=${latLng.lng || 0 - 0.01},${latLng.lat || 0 - 0.01},${latLng.lng || 0 + 0.01},${latLng.lat || 0 + 0.01}&layer=mapnik`}
                                        ></iframe>

                                        <Grid item xs={6}><h2>{item.Address_Line_One + ' ' + item.Address_Line_Two + ' ' + item.Address_City + ' ' + item.Address_State + ' ' + item.Address_Postcode}</h2>


                                            <h4>{'Status: ' + item.Status}</h4>
                                            <h5>{'Leave Parcel: ' + item.Shipment_Leave}</h5>
                                            <h5>{'Insturctions: ' + item.Shipment_Instructions}</h5>
                                            <h5>{'Releated: ' + item.Extra_One}</h5>

                                            <h4>{'Carrier: ' + item.Shipment_Carrier}</h4>
                                            <h5>{'Estimated Cost: ' + item.Shipment_Cost}</h5>
                                            <h5>{'Estimated Deliver: ' + item.Shipment_ETA}</h5>
                                            <h5>{'Consignment: ' + item.Shipment_Id}</h5>
                                        </Grid>
                                    </Grid>


                                    <h3>Package Items</h3>
                                    <DataGrid
                                        autoHeight
                                        rows={items.map((item) => ({

                                            id: item.$id,
                                            collection: item.$collectionId,
                                            package_type: item.Package_Name,
                                            package_length: item.Package_Length,
                                            package_width: item.Package_Width,
                                            package_height: item.Package_Height,
                                            package_weight: item.Package_Weight,
                                            package_quantity: item.Package_Quantity,
                                        }))
                                        }
                                        columns={
                                            [
                                                { field: 'package_type', headerName: 'Package Type', flex: 1 },
                                                { field: 'package_length', headerName: 'Package Length', flex: 1 },
                                                { field: 'package_width', headerName: 'Package Width', flex: 1 },
                                                { field: 'package_height', headerName: 'Package Height', flex: 1 },
                                                { field: 'package_weight', headerName: 'Package Weight', flex: 1 },
                                                { field: 'package_quantity', headerName: 'Package Quantity', flex: 1 },
                                            ]
                                        }
                                    />

                                    <h3>Options Available</h3>
                                    <DataGrid
                                        autoHeight
                                        rows={options.map((options) => ({

                                            id: options.$id,
                                            collection: options.$collectionId,
                                            carrier: options.Option_Carrier,
                                            service: options.Option_Service,
                                            price: options.Option_Price,
                                            date: options.Option_Estimate_Date,
                                        }))
                                        }
                                        columns={
                                            [
                                                { field: 'carrier', headerName: 'Package Carrier', flex: 1 },
                                                { field: 'service', headerName: 'Package Method', flex: 1 },
                                                { field: 'price', headerName: 'Estimated Price', flex: 1 },
                                                { field: 'date', headerName: 'Estimated Delivery', flex: 1 },
                                            ]
                                        }
                                    />

                                </div>
                            ))}

                        </Grid>
                    )}
                </Grid>
            </Grid>

            <Backdrop open={loading} sx={{ zIndex: 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </Box>
    );
};
