import { Box, Grid, CircularProgress, Backdrop, List, ListItem, ListItemText, Typography, ListSubheader, Hidden } from '@mui/material';

import React, { useState, useEffect, useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Moment from 'moment';

//import { Query } from "appwrite";
import { Query } from "appwrite";

import { database, functions } from 'utility/appwriteClient';
import { TentantContext } from "contexts/tentant";

import { BarChart } from '@mui/x-charts/BarChart';
import moment from 'moment';

export const DashboardScreen = () => {

    const [tentant] = useContext(TentantContext);

    const [todayTotal, setTodayTotal] = useState<number>(0);
    const [yesterdayTotal, setYesterdayTotal] = useState<number>(0);
    const [monthlyItems, setMonthlyItems] = useState<any>([]);
    const [monthlyDataset, setMonthlyDataset] = useState<any>([]);
    const [yesterdayDisplay, setYesterdayDisplay] = useState<any>();

    const todayStart = Moment().startOf('day').utc().toISOString();
    const todayEnd = Moment().startOf('day').utc().add(1, 'days').toISOString();

    var yesterdayStart = Moment().startOf('day').utc().subtract(1, 'days').toISOString();
    var yesterdayEnd = Moment().startOf('day').utc().toISOString();

    const monthStart = Moment().startOf('day').utc().subtract(30, 'days').toISOString();
    const monthEnd = Moment().startOf('day').utc().add(1, 'days').toISOString();


    useEffect(() => {

        if (tentant != '') {

            database.listDocuments(tentant.toLowerCase(), tentant.toLowerCase() + '_package', [Query.limit(1), Query.notEqual("Source", ''), Query.greaterThanEqual("$createdAt", todayStart), Query.lessThanEqual("$createdAt", todayEnd)])
                .then((response) => {
                    setTodayTotal(response.total);
                })

            database.listDocuments(tentant.toLowerCase(), tentant.toLowerCase() + '_package', [Query.limit(1), Query.notEqual("Source", ''), Query.greaterThanEqual("$createdAt", yesterdayStart), Query.lessThanEqual("$createdAt", yesterdayEnd)])
                .then((response) => {
                    if (response.total != 0) {
                        setYesterdayTotal(response.total);
                    } else {
                        yesterdayStart = Moment().startOf('day').utc().subtract(2, 'days').toISOString();
                        yesterdayEnd = Moment().startOf('day').utc().subtract(1, 'days').toISOString();
                        database.listDocuments(tentant.toLowerCase(), tentant.toLowerCase() + '_package', [Query.limit(1), Query.notEqual("Source", ''),  Query.greaterThanEqual("$createdAt", yesterdayStart), Query.lessThanEqual("$createdAt", yesterdayEnd)])
                            .then((response) => {
                                if (response.total != 0) {
                                    setYesterdayTotal(response.total);
                                    setYesterdayDisplay(yesterdayEnd);
                                } else {
                                    yesterdayStart = Moment().startOf('day').utc().subtract(3, 'days').toISOString();
                                    yesterdayEnd = Moment().startOf('day').utc().subtract(2, 'days').toISOString();
                                    database.listDocuments(tentant.toLowerCase(), tentant.toLowerCase() + '_package', [Query.limit(1), Query.notEqual("Source", ''),  Query.greaterThanEqual("$createdAt", yesterdayStart), Query.lessThanEqual("$createdAt", yesterdayEnd)])
                                        .then((response) => {
                                            setYesterdayTotal(response.total);
                                            setYesterdayDisplay(yesterdayEnd);
                                        })
                                }
                            })
                    }
                })

            database.listDocuments(tentant.toLowerCase(), tentant.toLowerCase() + '_package', [Query.select(["$createdAt", "Shipment_ETA", "Shipment_Cost"]), Query.limit(100000), Query.notEqual("Source", ''), Query.greaterThanEqual("$createdAt", monthStart), Query.lessThanEqual("$createdAt", monthEnd)])
                .then((response) => {

                    console.log(response)

                    const amountsPerDate = response.documents.map((i) => {
                        return {
                            shipmentCost: i['Shipment_Cost'],
                            monthDay: Moment(i['$createdAt']).format('DD-MM'),
                            date: i['$createdAt']
                        }
                    });

                    const uniqueDates = [] as any;
                    amountsPerDate.map(date => {
                        if (uniqueDates.indexOf(date['monthDay']) === -1) {
                            uniqueDates.push(date['monthDay'])
                        }
                    });

                    const totalSumPerDate = uniqueDates.map((dateAvailable: any) => {
                        return {
                            sumOnDate: amountsPerDate.reduce((acc, curr) => {
                                // return acc + (dateAvailable == curr['monthDay'] ? parseInt(curr['shipmentCost'] || 0) : 0);
                                return acc + (dateAvailable == curr['monthDay'] ? 1 : 0);
                            }, 0),
                            monthDay: dateAvailable
                        }
                    });

                    setMonthlyDataset(totalSumPerDate)


                    setMonthlyItems(response.documents as unknown);
                })
        }

    }, []);

    const valueFormatter = (value: number) => `${value} Parcels`;

    const avgDeliveryDays = Math.round((monthlyItems.reduce((a: any, b: any) => a + (Moment(b['Shipment_ETA'], ['YYYY-MM-DD']).diff(Moment(b['$createdAt'], ['YYYY-MM-DD']), 'days') || 0), 0) / monthlyItems.length) * 100) / 100

    if (tentant != '' && monthlyItems.length > 0) {

        return (
            <Box sx={{ p: 4, borderRadius: 4, backgroundColor: 'background.paper' }}>

                <h1>Dashboard</h1>

                <div style={{ display: "flex", justifyContent: "space-evenly", textAlign: 'center' }}>

                    <Box sx={{ p: 4, borderRadius: 4, minWidth: 220, maxWidth: 300, maxHeight: 250, backgroundColor: 'primary.main' }}>

                        <Typography variant="h6" gutterBottom component="div">
                            Lodgements <br /> Today
                        </Typography>

                        <Typography variant="h3" gutterBottom component="div">
                            {todayTotal}
                        </Typography>

                        {Moment(todayEnd).format('DD-MM-YYYY')}

                    </Box>

                    <Box sx={{ p: 4, borderRadius: 4, minWidth: 220, maxWidth: 300, maxHeight: 250, backgroundColor: 'primary.main' }}>

                        <Typography variant="h6" gutterBottom component="div">
                            Lodgements <br /> Yesterday
                        </Typography>

                        <Typography variant="h3" gutterBottom component="div">
                            {yesterdayTotal}
                        </Typography>

                        {Moment(yesterdayDisplay).format('DD-MM-YYYY')}

                    </Box>

                    <Box sx={{ p: 4, borderRadius: 4, minWidth: 220, maxWidth: 300, maxHeight: 250, backgroundColor: 'primary.main' }}>

                        <Typography variant="h6" gutterBottom component="div">
                            Average Cost <br /> Per Lodgement
                        </Typography>

                        <Typography variant="h3" gutterBottom component="div">
                            ${Math.round((monthlyItems.reduce((a: any, b: any) => a + (parseInt(b['Shipment_Cost']) || 0), 0) / monthlyItems.length) * 100) / 100}
                        </Typography>

                        Last 30 Days

                    </Box>

                    {avgDeliveryDays > 0 ?

                        <Box sx={{ p: 4, borderRadius: 4, minWidth: 220, maxWidth: 300, maxHeight: 250, backgroundColor: 'primary.main' }}>

                            <Typography variant="h6" gutterBottom component="div">
                                Average Estimate <br /> Delivery Days
                            </Typography>

                            <Typography variant="h3" gutterBottom component="div">
                                {Math.round((monthlyItems.reduce((a: any, b: any) => a + (Moment(b['Shipment_ETA'], ['YYYY-MM-DD']).diff(Moment(b['$createdAt'], ['YYYY-MM-DD']), 'days') || 0), 0) / monthlyItems.length) * 100) / 100}
                            </Typography>

                            Last 30 Days

                        </Box> : ''
                    }


                </div>



                <div style={{ display: "block", textAlign: 'center', paddingTop: '40px' }}>

                    <Typography variant="h6" gutterBottom component="div">
                        Parcel Deliveries Last 30 Days
                    </Typography>


                    <BarChart
                        dataset={monthlyDataset}
                        xAxis={[{ scaleType: 'band', dataKey: 'monthDay' }]}
                        series={[
                            { dataKey: 'sumOnDate', label: 'Parcels', color: '#F57C00', valueFormatter }
                        ]}
                        height={400}
                        leftAxis={null}
                        slotProps={{
                            legend: {
                                hidden: true
                            },
                        }}
                    />


                </div>


            </Box >


        )
    } else {
        return (<div>Loading...</div>)
    }
}